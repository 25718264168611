.table-list-wrapper {
  display: flex;
  flex-direction: column;
  .table-list-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .table-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
    .table-list-labels {
      display: flex;
      justify-content: center;
      padding: 0 24px;
      span {
        color: #666;
        font-size: 12px;
        flex-grow: 1;
        text-align: center;
        width: 100%;
        &:first-of-type {
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
      }
    }
    .table-list-row {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #f4f4f4;
      border-radius: 10px;
      padding: 14px 24px;
      font-size: 12px;
      cursor: pointer;
      transition: ease all 0.3s;
      .table-list-item {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        width: 100%;
        &:first-of-type {
          justify-content: flex-start;
        }
        &:last-of-type {
          justify-content: flex-end;
          text-align: right;
        }
      }
      &:hover {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      }
      svg {
        transform: rotate(-90deg);
      }
    }
  }
}
