.single-view-statuses {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 120px 120px;
  justify-items: stretch;
  gap: 17px;
  .card-filter {
    max-width: 140px;
    .title {
      color: #fff;
    }
  }
}
.status-item {
  padding: 0 30px 0 0;
}
.single-view-header-filters {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  h2 {
    margin: 0;
  }
}

.no-feedbacks {
  font-size: 16px;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  padding: 8px 18px;
}

.feedbacks-list {
  h2 {
    margin-bottom: 20px;
  }
  ul {
    font-size: 12px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 27em;
    overflow: scroll;
    li {
      display: flex;
      .column.list-header {
        padding: 0;
      }
      .column {
        width: 33.33%;
        padding: 13px 0;
        &:first-child {
          padding-left: 25px;
        }
        &:last-child {
          padding-right: 13px;
        }
      }
    }
    li:not(:first-of-type) {
      border: 1px solid #f4f4f4;
      border-radius: 10px;
      .sender {
        font-weight: bold;
      }
      .feedback-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        .info {
          color: #6a6565;
          padding-left: 25px;
          .title {
            color: #6a6565;
            font-size: 12px;
          }
        }
        .action {
          height: 100%;
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}
