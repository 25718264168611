@media (max-width: 640px) {
  .table-scroll {
    width: calc(100vw - 60px);
    overflow-x: scroll;
    table {
      min-width: 600px;
    }
  }
}
@media (max-width: 375px) {
  .modal-dialog {
    margin: 0;
    align-items: flex-end;
    .modal-content {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      .modal-header {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .modal-footer {
        button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
