.trend-filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding: 12px 8px;
  border: 2px solid #d5e7f2;
  border-radius: 12px;
  font-size: 14px;
  .trend-filter-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .percentage-icon {
      display: flex;
      align-items: center;
      box-shadow: 0 0 0 1px #a9ce5b;
      border-radius: 100px;
      max-height: 22px;
      span {
        padding: 0 4px 0 0;
        font-size: 12px;
      }
      svg {
        stroke: transparent;
        stroke-width: 0px;
      }
    }
    .percentage-icon.red-border {
      box-shadow: 0 0 0 1px #f07171;
    }
    .trend-count {
      font-size: 24px;
    }
  }
}
