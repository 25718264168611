$borderColor: #f4f4f4;
$primaryColor: #0f2a3c;
$secondaryColor: #6a6565;
.performance-review {
  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border: 1px solid $borderColor;
    border-radius: 10px;
    padding: 15px 0;
    overflow: hidden;
    position: relative;
    .columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0 15px 0 25px;
      height: inherit;
    }
  }
  .column {
    width: calc(100% / 7);
    display: flex;
    flex-direction: column;
    justify-content: start;
    &:first-child {
      text-align: left;
    }
    &.span-6 {
      width: calc((100% / 7) * 6);
    }
  }
  .timelines {
    display: flex;
    gap: 45px;
    justify-content: space-between;
    .point-on-click {
      position: absolute;
      transform: translate(-50%, -120%);
      padding: 10px 10px;
      background-color: #fff;
      border-color: $borderColor;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      .review-btn {
        padding: 8px 15px;
      }
    }
    .timeline-wrapper {
      width: 70%;
    }
    .side-timelines {
      width: fit-content;
      .reviews-timeline-list {
        .reviews-timeline-list-header {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          gap: 10%;
          .reviews-timeline-list-title {
            font-size: 12px;
            width: max-content;
          }
        }
        .timeline-list {
          height: 100px;
        }
      }
    }
  }
  .review-lists {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #0f2a3c;
    h4 {
      font-size: 14px;
    }
    .min-h-130 {
      min-height: 100px;
    }
    .list {
      max-height: 39em;
      overflow: scroll;

      .point-wrapper {
        display: flex;
        gap: 8px;
      }
      .row {
        gap: 30px;
        height: 5.5em;
        flex-shrink: 0;
        &.expanded {
          height: auto;
          padding-bottom: 0;
          .columns {
            height: auto;
            .action-column {
              height: auto;
              .actions {
                bottom: 0;
                .expand-button {
                  svg {
                    transform: rotate(540deg);
                  }
                }
              }
            }
          }
        }
        &.editing {
          padding-bottom: 15px;
        }
        .conclusion-mark {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 15px;
        }
        .columns {
          .column {
            height: inherit;
            .review-sender {
              font-weight: bold;
            }
            .checklist {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 35px;
              color: $secondaryColor;
              &.responsibilities {
                margin-left: 45px;
              }
              &.behaviors {
                margin-left: 65px;
              }
              &.interests {
                margin-left: 65px;
              }
              &.skills {
                margin-left: 77px;
              }
            }
            &.action-column {
              position: relative;
              .actions {
                height: 30px;
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 32px;
                right: 0;
                transition: bottom 0.3s ease-in-out;
                .vertical-line {
                  height: 100%;
                  width: 1px;
                  background-color: #e1e1e1;
                }
                svg {
                  transition: transform 0.3s ease-in-out;
                }
              }
            }
          }
        }
        .expectations {
          border-bottom: 1px solid #e1e1e1;
          background-color: #f5f5f5;
          border-radius: 10px;
          overflow: hidden;
          .expectation-row {
            color: $primaryColor;
            margin-top: 15px;
            margin-bottom: 15px;
            max-height: 3.5em;
            overflow: hidden;
            &.expanded-expectation {
              max-height: inherit;
              .columns {
                max-height: inherit;
              }
              .expectation-action-column {
                max-height: inherit;
                .expectation-actions {
                  transform: translateY(0) !important;
                  bottom: 0 !important;
                  svg {
                    transform: rotate(540deg);
                  }
                }
              }
            }
            .columns {
              max-height: inherit;
              .column {
                gap: 5px;
                p {
                  color: $secondaryColor;
                  &:first-child {
                    color: #0f2a3c;
                  }
                }
                &.expectation-action-column {
                  position: relative;
                  .expectation-actions {
                    position: absolute;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    bottom: 50%;
                    transform: translateY(50%);
                    right: 0;
                    transition: all 0.3s ease-in-out;
                    .vertical-line {
                      height: 100%;
                      width: 1px;
                      background-color: #e1e1e1;
                    }
                    svg {
                      transition: transform 0.3s ease-in-out;
                    }
                  }
                }
              }
            }
          }
        }
        .review-conclusion {
          display: flex;
          gap: 30px;
          .conclusion-option {
            color: $secondaryColor;
          }
        }

        .review-btn {
          padding: 7px 25px;
          font-size: 12px;
          border-radius: 40px;
        }
      }
    }
  }
}
