.single-view {
  font-size: 12px;
  .initiative-review {
    display: flex;
    gap: 40px;
    .initiative {
      width: 70%;
      .current-path-list {
        margin-bottom: 15px;
      }
      .title-line {
        button {
          gap: 13px;
          font-size: 12px;
          svg {
            width: 16px;
            height: 16px;
            path {
              stroke: #5f6368;
            }
          }
        }
      }
      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 21em;
        overflow: scroll;
        li {
          display: flex;
          align-items: center;
          padding: 15px 15px 15px 24px;
          &:not(:first-of-type) {
            border: 1px solid #f4f4f4;
            border-radius: 10px;
          }
          &:first-of-type {
            padding: 0 15px 0 24px;
          }
          .column {
            width: 100%;
            height: 100%;
            display: flex;

            &:first-child {
              flex-direction: column;
              align-items: start;
            }
            &:last-child {
              gap: 12px;
              align-items: center;
              .vertical-line {
                height: 32px;
              }
            }
            .name {
              font-weight: bold;
            }
            .progress-wrapper {
              position: relative;
              max-height: 28px;
              .progress-bar {
                height: 28px;
                .progress-scale.green {
                  background-color: #a9ce5b;
                }
                .progress-scale.red {
                  background-color: #f07171;
                }
              }
              p {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
              }
            }
          }
        }
      }
    }
    .graph {
      width: 30%;
    }
  }
  .compensation-review {
    display: flex;
    gap: 40px;
    .current-compensation {
      width: 70%;
      .review-sumary {
        padding: 0;
        margin: 15px 0;
        li {
          display: flex;
          align-items: center;
          padding: 13px 13px 13px 24px;
          &:first-of-type {
            padding: 0 13px 10px 24px;
          }
          &:not(:first-of-type) {
            border: 1px solid #f4f4f4;
            border-radius: 10px;
          }
          .column {
            width: 100%;
            display: flex;
            justify-content: center;
            &:first-of-type {
              flex-direction: column;
              .sender {
                font-weight: bold;
              }
            }
          }
        }
      }
      .requirements {
        display: grid;
        grid-template-columns: 48% 48%;
        justify-content: space-between;
        row-gap: 10px;
        padding: 0;
        li {
          border: 1px solid #f4f4f4;
          border-radius: 10px;
          padding: 10px 10px 10px 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 15px;
          width: 100%;
          .requirement-title {
            font-weight: bold;
          }
          .finished {
            svg {
              path {
                fill: #a9ce5b;
              }
            }
          }
          .unfinished {
            svg {
              path {
                fill: #d9d9d9;
              }
            }
          }
        }
      }
    }
    .compensation-history {
      width: 30%;
      border: 1px solid #f4f4f4;
      border-radius: 24px;
      padding: 25px;
      height: 26em;
      .timeline-list {
        height: 19em;
        .item {
          margin: 0;
        }
      }
    }
  }
}
