.title {
  display: flex;
  color: #0f2a3c;
  font-size: 14px;
  font-weight: bold;
  justify-self: start;
  margin: 0;
  align-items: center;
}
.goals {
  font-size: 12px;
  display: flex;
  gap: 40px;
  .goals-table {
    width: 70%;
    .filter-wrapper {
      .show-all {
        color: #666;
        svg {
          rotate: 270deg;
        }
      }
    }
    .table-wrapper {
      table {
        tbody {
          td {
            .goal-list {
              justify-content: start;
              text-align: start;
              .goal-title {
                font-weight: bold;
              }
            }
            .actions {
              display: flex;
              gap: 10px;
              justify-content: end;
              text-align: right;
              align-items: center;
            }
            .vertical-line {
              width: 1px;
              height: 2em;
              background-color: #f4f4f4;
            }
          }
        }
      }
    }
  }
  .datepicker-wrapper {
    width: 30%;
    .date-picker-holder {
      .highlighted-dates {
        border-radius: 50%;
        color: #fff;
      }
      .react-datepicker {
        border: none;
      }
      .custom-day {
        margin: 5px 7px;
      }
    }
  }
}
.surveys {
  font-size: 12px;
  display: flex;
  gap: 40px;
  .survey-table {
    width: 70%;
  }
  .chart {
    width: 30%;
    display: flex;
    gap: 10px;
    .card-filters {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 40%;
      .card-filter {
        cursor: default;
        .title {
          color: #fff;
        }
        svg {
          fill: #fff;
          width: 20px;
          height: 20px;
        }
        .uncompleted {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
    .pie {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;
      h4 {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
.completed {
  background-color: #a9ce5b;
}
.overdue {
  background-color: #f07171;
}
.underdue {
  background-color: #14b4ba;
}

.single-view {
  .title-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .filter-wrapper {
      display: flex;
      gap: 25px;
      justify-content: end;
    }
  }
  .table-wrapper {
    height: 27em;
    padding: 0;
    table {
      border: none;
      border-collapse: separate;
      border-spacing: 0 10px;
      thead {
        tr {
          border: none;
          th:first-of-type {
            text-align: start;
          }
          th {
            padding: 0 10px;
            text-align: center;
            font-weight: normal;
          }
        }
      }
      tbody {
        tr {
          td:first-of-type {
            border: 1px solid #f5f5f5;
            border-right: none;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding-left: 20px;
            .table-data-wrapper {
              justify-content: start;
            }
          }
          td {
            border-width: 1px 0;
            border-style: solid;
            border-color: #f5f5f5;
            font-size: 12px;
            div {
              justify-content: center;
              text-align: center;
            }
            .progress {
              padding: 2px 70px;
              align-items: center;
              color: #fff;
              border-radius: 64px;
            }
          }
          td:last-of-type {
            border: 1px solid #f5f5f5;
            border-left: none;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            text-align: end;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
