.typeahead-wrapper {
  position: relative;
  width: 100%;
  label {
    margin: 0 0 8px 0;
    color: #6a6a6a;
    font-size: 14px;
    display: block;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateX(100%) translateY(-40%);
    width: 14px;
    height: 14px;
  }

  input {
    &.primary {
      max-height: 48px;
      font-size: 14px;
      padding: 14px 20px;
      display: flex;
      border-radius: 6px;
      background-color: #f5f5f5;
      border: 1px solid #e1e1e1;
      margin-bottom: 24px;
      width: 100%;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      outline: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:focus {
        outline: none;
        border-color: #86b7fe;
      }
    }

    &.icon-input {
      padding-left: 35px;
      margin: 0;
    }
  }

  .typeahead-search {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px 0;

    .typeahead-item {
      cursor: pointer;
      padding: 5px 14px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .typeahead-no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: #a5a5a5;
    }
  }

  .typeahead-close {
    position: absolute;
    top: 50%;
    right: 25px;
    min-width: 15px;
    background-color: #f5f5f5;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      width: 10px;
    }
    &:hover {
      color: #6a6a6a;
    }
  }
}

.typeahead-selected {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 5px 10px;
  justify-content: space-between;
  margin-bottom: 14px;

  .typeahead-selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }

  .typeahead-selected-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #a1a1a1;
    border-radius: 6px;
    cursor: pointer;
    color: #6a6a6a;
    padding: 0.25em 0.5em;
    svg {
      width: 8px;
    }
    &:hover {
      color: #000;
    }
  }
}
