._pagination {
  display: flex;
  margin-top: 20px;
  &.center {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.start {
    justify-content: flex-start;
  }
  ._page-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    &._active {
      background-color: #0f2a3c;
      color: #fff;

      &:hover {
        background-color: #0f2a3c;
      }
    }
  }
  ._page-item:hover {
    background-color: rgba(241, 241, 241, 1);
  }
  .controls {
    font-size: 30px;
    &:hover {
      background-color: transparent;
    }
  }
}

._page-link {
  display: flex;
  align-items: center;
  background-color: initial;
  max-height: 36px;
  margin-bottom: 5px;
}
