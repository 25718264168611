.add-task {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #4571f3;
  margin: 5px 0 24px;
  cursor: pointer;
  path {
    fill: #4571f3;
  }
}
.selected-list {
  list-style: none;
  padding: 0;
  margin: 16px 0;
  li {
    display: flex;
    justify-content: space-between;
    color: #4571f3;
    text-decoration: underline;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
}
.back {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  &::before {
    content: "";
    display: inline-block;
    margin-right: 15px;
    width: 8px;
    height: 8px;
    transform: rotate(135deg);
    border-bottom: 2px solid #6a6565;
    border-right: 2px solid #6a6565;
  }
}

.checkbox-with-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  input {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    &:checked {
      background-color: #0f2a3c;
    }
    &:focus {
      outline: none;
      border: 1px solid #e1e1e1 !important;
    }
  }
}

.feedback-note {
  display: flex;
  gap: 8px;
  svg {
    width: 20px;
  }
  p {
    color: #6a6565;
    font-size: 14px;
  }
}

.feedback-input-item {
  color: #b2afaf;
  background-color: #f5f5f5;
  padding: 14px 20px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  font-size: 14px;
}

.title-action {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.career-form-group {
  margin-bottom: 0;
  input {
    min-width: initial !important;
  }
}
