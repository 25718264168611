.card-filter {
  background-color: #fff;
  border: 3px solid black;
  border-radius: 12px;
  padding: 8px 15px 15px 20px;
  color: #000;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .title {
    font-size: 16px;
  }

  .title-icon {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .icon {
    width: 22px;
    height: 22px;
  }

  .count {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
  }
  .card-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.green {
  background-color: #a9ce5b;
  border-color: #86a347;
  color: #fff;
}
.blue {
  background-color: #14b4ba;
  border-color: #109095;
  color: #fff;
}
.red {
  background-color: #f07171;
  border-color: #b35656;
  color: #fff;
}
.purple {
  background-color: #96a3e4;
  border-color: #6f7aaf;
  color: #fff;
}
.yellow {
  background-color: #f0e371;
  border-color: #bcb152;
  color: #fff;
}
