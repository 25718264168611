.surveys-wrapper {
  display: block;

  .surveys-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 30px;
    border-bottom: 1px solid #f4f4f4;
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .header-actions {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }

  .sort {
    display: flex;
    justify-content: right;
    padding: 30px 30px 0 0;
  }
}
