$switch-width: 67px;
$switch-height: 26px;
$ball-width-height: 20px;
$ball-distance: 3px;

.switcher {
  width: $switch-width;
  min-width: $switch-width;
  height: $switch-height;
  border-radius: 60px;
  background-color: #6a6565;
  position: relative;
  cursor: pointer;
  transition: all ease 0.3s;
}
.switcher.checked {
  background-color: #a9ce5b;
}
.switcher-thumb {
  width: $ball-width-height;
  height: $ball-width-height;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: $ball-distance;
  transform: translateY(-50%);
  transition: all ease 0.3s;
}
.switcher.checked .switcher-thumb {
  left: $switch-width - $ball-width-height - $ball-distance;
  transition: all ease 0.3s;
}
