.select-list {
  margin-bottom: 25px;
  .selected-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style: none;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 12px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 48px;
      background-color: #f5f5f5;
      padding: 14px 20px;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      .remove-item {
        svg {
          width: 10px;
          path {
            fill: #666666;
          }
        }
      }
    }
  }
  input {
    margin-bottom: 13px;
  }
  .add-label {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    color: #4570f3;
    path {
      fill: #4570f3;
    }
  }
}
