.client-question-wrapper {
  .question {
    padding: 18px;
    background-color: #f5f5f5;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .question-answer {
    .input-wrapper {
      width: 70%;
      input {
        border: none;
        border-bottom: 1px solid #e1e1e1;
        border-radius: unset;
        background-color: transparent;
      }
    }
    .answer-list {
      li {
        gap: 8px;
        align-items: center;
      }
      input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: 2px solid #fff;
        outline: 1px solid #5f6368;
        cursor: pointer;
        padding: 2px;
      }
      input[type="radio"]:checked {
        background-color: #5f6368;
      }
      input[type="checkbox"] {
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 17px;
        height: 17px;
        border-radius: 3px;
        border: 2px solid #fff;
        outline: 1px solid #5f6368;
        cursor: pointer;
        padding: 2px;
      }
      input[type="checkbox"]:checked {
        &::before {
          content: "";
          position: absolute;
          top: 2px;
          left: 1px;
          width: 11px;
          height: 6px;
          border-bottom: 2px solid #6a6565;
          border-left: 2px solid #6a6565;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .question-required {
    padding: 5px 20px;
    background-color: #6a6565;
    color: #fff;
    border-radius: 100px;
  }
}

.client-survey-actions {
  padding: 20px 40px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.question-creator {
  margin-top: 0;
  max-height: calc(100vh - 320px);
}
