$triangle-position: 10%;

.filter {
  color: #666;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  .controls {
    display: flex;
    width: fit-content;
    gap: 15px;
    .reset-wrapper {
      width: 115px;
    }
    .hidden {
      display: none;
    }
  }
  .filters-container {
    width: max-content;
    position: absolute;
    top: 25px;
    z-index: 100;
    .filters {
      display: inline-block;
      position: relative;
      border: 1px solid #cbcaca;
      background-color: #f5f5f5;
      text-decoration: none;
      border-radius: 6px;
      padding: 8px;
      padding-right: 30px;
      margin-top: 12px;
      width: fit-content;
      &::before {
        content: "";
        display: block;
        position: absolute;
        right: $triangle-position;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #cbcaca;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: calc($triangle-position + 1px);
        bottom: 100%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: #f5f5f5;
      }
      input {
        width: 13px;
        height: 13px;
      }
      label {
        font-size: 12px;
      }
    }
  }
  .hidden {
    display: none;
  }
}
