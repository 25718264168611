.line-chart-wrapper {
  border: 1px solid #d5e7f2;
  border-radius: 6px;
  padding: 10px;
  .outside-title {
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
