.input-wrapper {
  width: 100%;
  position: relative;
  label {
    margin: 0 0 8px 0;
    color: #6a6a6a;
    font-size: 14px;
    display: block;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateX(100%) translateY(-40%);
  }
  input,
  textarea {
    max-height: 48px;
    font-size: 14px;
    background-color: #f5f5f5;
    padding: 14px 20px;
    display: flex;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    margin-bottom: 24px;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    &:focus {
      outline: none;
      border-color: #86b7fe;
      background-color: #fff;
    }
  }

  .icon-input {
    padding-left: 53px;
    margin: 0;
  }

  .light {
    background-color: #fff;
  }
}
