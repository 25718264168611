.custom-recurring {
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 16px;

  h3 {
    font-size: 14px;
    color: #6a6a6a;
  }
  input {
    outline: none;
    border: 1px solid #cbcaca;
    border-radius: 6px;
    background-color: #f5f5f5;
    font-size: 12px;
    padding: 5px;
  }
  .custom-recurring-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    p {
      font-size: 12px;
      color: #6a6a6a;
      min-width: max-content;
    }
    .custom-recurring-top-left {
      display: flex;
      align-items: center;
      gap: 10px;
      .recurring-repeat-interval {
        font-size: 12px;
        position: relative;
        .repeat-selected {
          background-color: #f5f5f5;
          border: 1px solid #cbcaca;
          border-radius: 6px;
          padding: 6px 12px;
          display: flex;
          gap: 10px;
          align-items: center;
          &::after {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border-bottom: 1px solid #6a6565;
            border-right: 1px solid #6a6565;
            transform: translateY(2px) rotate(-135deg);
          }
        }
        .repeat-selected.active {
          &::after {
            transform: translateY(-2px) rotate(45deg);
          }
        }
        ul {
          position: absolute;
          right: 0;
          left: 0;
          background-color: #f5f5f5;
          border: 1px solid #cbcaca;
          border-radius: 6px;
          padding: 6px 12px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin: 0;
          li {
            cursor: pointer;
          }
        }
      }
    }
    .custom-recurring-top-right {
      display: flex;
      align-items: center;
      gap: 10px;
      .custom-days {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          color: #6a6a6a;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          font-size: 12px;
          cursor: pointer;
          transition: ease all 0.2s;
        }
        li.active {
          color: #fff;
          background-color: #3bc4e8;
        }
      }
    }
  }
  .arrow-inputs {
    input {
      max-width: 30px;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .input-arrows {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .arrow {
        border: none;
        outline: none;
        background-color: transparent;
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          display: block;
          border-bottom: 1px solid #6a6a6a;
          border-right: 1px solid #6a6a6a;
          transform: rotate(-135deg);
        }
      }
      .arrow.down {
        &::before {
          transform: rotate(45deg);
        }
      }
    }
  }
  .custom-recurring-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    font-size: 12px;
    color: #6a6a6a;
    .custom-recurring-radio-list {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      .date-picker-holder {
        input {
          padding: 5px 10px 5px 35px;
          max-width: 150px;
          min-width: auto;
        }
        svg {
          left: 10px;
          top: 5px;
        }
      }
      .custom-recurring-radio {
        display: flex;
        align-items: center;
        gap: 5px;
        .checkbox-wrapper {
          gap: 0;
        }
        label {
          margin-bottom: 0;
          font-size: 12px;
        }
      }
    }
  }
}
