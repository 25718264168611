.messages-item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .default-image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .single-message {
    img {
      border-radius: initial;
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
      .single-message .message-body {
        font-size: 16px;
        color: #6a6565;
        margin: 0;
      }
      span {
        font-size: 12px;
        color: #a1a1a1;
        margin-left: 14px;
        font-weight: 400;
      }
    }
    .message-body img {
      max-width: 50%;
    }
  }
}

.unavailable-users {
  text-align: center;
  .unavailable-item {
    position: relative;
    .text-center {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      background-color: #fff;
      padding: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      background-color: #f4f4f4;
    }
    p {
      margin: 0;
      color: #a1a1a1;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      opacity: 0.5;
    }
  }
}

figure span img {
  max-width: 100px !important;
}
