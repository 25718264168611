.user-info-wrapper {
  min-width: 400px;
  max-height: calc(100vh - 185px);
  border-left: 1px solid #f4f4f4;
  padding: 20px 30px 30px;
  overflow-y: scroll;

  .user-image {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 250px;
      min-height: 280px;
      font-size: 100px;
      border-radius: 6px;
      background-color: #ccc;
      color: #fff;
      cursor: default;
    }
    .user-icon-select {
      position: absolute;
      top: 10px;
      right: 8px;
      background-color: #f4f4f4;
      border: none;
      border-radius: 6px;
      height: 26px;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    img {
      width: 250px;
      border-radius: 6px;
    }

    .photo-holder {
      max-width: 250px;
      margin: 0 auto;
      position: relative;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }
  }
}

.user-info-wrapper .user-info-form {
  margin-top: 20px;
}

.user-info-form label {
  color: #6a6a6a;
  font-size: 14px;
}

.user-info-form input {
  margin: 0;
  font-size: 14px;
  padding: 17px 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 8px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.date-picker-holder {
  position: relative;
  width: 100%;
}

.date-picker-holder input {
  width: 100%;
}

.date-picker-holder svg {
  position: absolute;
  top: 50%;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
}

.user-icon-select input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.user-icon-select input[type="file"]::before {
  content: "";
}

.user-icon-select input[type="file"]:not(:empty)::before {
  content: "File selected";
}

.user-icon-select input[type="file"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.disable-date-picker {
  pointer-events: none;
  background-color: #f4f4f4;
  color: #a5a5a5;
}
