.modal-shell {
  .modal-dialog {
    .modal-content {
      width: fit-content;
      .modal-body {
        display: flex;
        overflow: hidden;
        .modal-views {
          border-right: 1px solid #d9d9d9;
          padding-right: 15px;
          margin-right: 15px;
          width: max-content;
          label {
            font-size: 12px;
          }
          ul {
            padding: 0;
            li {
              margin: 31px 0;
              &:first-child {
                margin-top: 17px;
              }
              .checkbox-wrapper {
                input {
                  margin: 0;
                }
              }
            }
          }
        }
        .modal-panel {
          overflow: scroll;
          flex: 1;
          label {
            color: #0f2a3c;
            font-weight: bold;
            font-size: 12px;
          }
          .input-btn {
            position: relative;
            input {
              margin-bottom: 10px;
            }
            .btn-input {
              position: absolute;
              height: 40px;
              width: 93px;
              gap: 10px;
              padding: 10px;
              bottom: 14px;
              right: 4px;
            }
          }
          label.label-normal {
            font-weight: normal;
            color: #6a6a6a;
            margin: 0;
          }
          .switcher-group,
          .date-group {
            justify-content: flex-end;
            align-items: center;
          }
          .speakers-list {
            padding: 10px;
            border: 1px solid #cbcaca;
            border-radius: 6px;
            color: #6a6a6a;
            li {
              display: flex;
              justify-content: space-between;
              height: 18px;
              &:not(:last-child) {
                margin-bottom: 15px;
              }
              &:hover {
                .hidden {
                  display: flex;
                }
              }
              .hidden {
                display: none;
              }
            }
          }
          .topics-list {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            border: 1px solid #e1e1e1;
            border-radius: 6px;
            background-color: #f5f5f5;
            width: 100%;
            li {
              display: flex;
              gap: 10px;
              background-color: #fff;
              border-radius: 40px;
              padding: 5px 16px;
              width: fit-content;
            }
          }
          .conference-attendance {
          }
          .workshop-seminars {
            .or-switch {
              background-color: #4795f5;
            }
            .or-switch.checked {
              background-color: #a9ce5b;
            }
          }
        }
      }
    }
  }
}
.save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
