$backgroundColor: #f5f5f5;
$borderColor: #cbcaca;

.dropdown-wrapper {
  position: relative;
  cursor: pointer;
  .dropdown-input {
    gap: 10px;
    background-color: $backgroundColor;
    padding: 10px;
    border: 1px solid $borderColor;
    width: inherit;
    border-radius: 10px;
    .dropdown-single-seleced-label {
      font-size: 14px;
      cursor: pointer;
      color: #6a6565;
    }

    .multi-results {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      .selected-item {
        background-color: #fff;
        color: #666666;
        font-size: 14px;
        padding: 4px 16px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        svg {
          width: 10px;
          path {
            fill: #666;
          }
        }
      }
      .placeholder {
        font-size: 14px;
        color: #6a6565;
      }
    }
    .btn-container {
      display: flex;
      gap: 10px;

      button {
        padding: 0;
        svg {
          width: 10px;
          height: 10px;
          path {
            fill: #6a6565;
          }
        }
      }
      .arrow-down {
        margin-top: 4px;
        width: 8px;
        height: 8px;
        border-bottom: 1px solid #666;
        border-right: 1px solid #666;
        transform: rotate(45deg);
      }
    }
  }
  .dropdown-select {
    width: inherit;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    .options {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: inherit;
      background-color: #f5f5f5;
      border: 1px solid $borderColor;
      border-top: none;
      border-radius: 0 0 10px 10px;
      padding: 10px;
      label {
        margin: 0;
      }
    }
    .hidden {
      display: none;
    }
  }
}

.dropdown-wrapper[data-options-hidden="false"] {
  .dropdown-input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
}
