button.custom-button {
  background-color: #0f2a3c;
  border: none;
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 18px 30px;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  gap: 18px;
  font-weight: 400;
  font-family: inherit;
  cursor: pointer;
  &:disabled {
    background-color: #87949d;
    color: #fff;
    cursor: not-allowed;
  }
}

button.custom-button.secondary {
  background-color: transparent;
  color: #0f2a3c;
}

button.custom-button.action {
  display: inline-flex;
  justify-content: center;
  background-color: #f5f5f5;
  height: 32px;
  width: 32px;
  padding: 7px;
  color: inherit;
}

button.custom-button.control {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
  color: inherit;
}

button.custom-button.link {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 1px 6px;
  text-decoration: underline;
  color: #6d28d9;
}
