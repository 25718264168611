.career-path-wrapper {
  display: flex;

  .module-content {
    flex-grow: 1;
    .career-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 24px 20px 30px;
      h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .header-actions {
        display: flex;
        gap: 30px;
        align-items: center;
      }
    }
  }
}

.career-content {
  padding: 0 24px 0 30px;

  .table {
    border: 1px solid #f5f5f5;
    tr {
      border-bottom: 1px solid #f5f5f5;
    }
    thead {
      th {
        font-size: 12px;
        color: #666666;
        font-weight: 400;
        padding: 20px;
      }
    }
    tbody {
      td {
        font-size: 14px;
        padding: 20px;
        max-height: 64px;
        vertical-align: middle;
      }
      td.clickable {
        color: #4571f3;
        cursor: pointer;
      }
      td.action {
        padding: 0 16px 0 0;
        text-align: right;
      }
    }
  }
}
