.message {
  display: flex;
  align-items: center;
  padding: 20px;
}

.message > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.message-info {
  margin-left: 10px;
}

.message-info > h4 {
  display: flex;
  align-items: center;
}

.message-timestamp {
  margin-left: 10px;
  color: var(--gray);
  font-weight: 300;
  font-size: 13px;
}

@media only screen and (max-width: 400px) {
  .message-info > h4 {
    margin-top: 10px;
  }
}
