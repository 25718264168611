.custom-block {
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 10px 15px;
  font-size: 14px;
  display: flex;
  gap: 10px;
}
.updatenode__controls {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 4;
}
