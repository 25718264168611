.date-filter {
  display: flex;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
      border-radius: 6px;
      padding: 17px 12px;
      border: none;
      font-size: 14px;
    }
  }
  .reset-filter {
    border: none;
    background-color: transparent;
    min-width: 32px;
    svg {
      position: static;
      width: 10px;
      path {
        fill: #666;
      }
    }
  }
}

.list-tasks-modal {
  .modal-dialog {
    max-width: 800px;
  }
}
.modal-status {
  .modal-content {
    height: 360px;
    position: relative;
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  label {
    margin-bottom: 10px;
  }

  .modal-status-footer {
    display: flex;
    justify-content: end;
    padding: 20px 30px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.modal-task-options {
  padding: 20px 0;
  border-bottom: 1px solid #f1f1f1;
  .option-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      max-width: 27%;
    }
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      border: 1px solid #f4f4f4;
      border-radius: 12px;
      overflow: hidden;
      li {
        font-size: 14px;
        padding: 18px;
        border-right: 1px solid #f4f4f4;
        transition: all ease 0.2s;
        cursor: pointer;
        &:last-child {
          border-right: none;
        }
        &:hover {
          background-color: #f4f4f4;
        }
      }
      li.active {
        background-color: #f4f4f4;
      }
    }
    .custom-input {
      max-width: 150px;
      border: 2px solid #f4f4f4;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px 15px;
      margin-bottom: 0;
      label {
        text-transform: uppercase;
        font-size: 10px;
      }
      input {
        margin: 0;
        padding: 3px 0 0;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
    .disabled-input {
      background-color: #f4f4f4;
    }
    .input-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
