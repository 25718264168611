.checkins-wrapper {
  // .typeahead-selected {
  //   display: none;
  // }
  .icon-wrapper {
    border-radius: 6px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 35px;
    border-bottom: 1px solid #f4f4f4;
    input {
      margin: 0;
      background-color: #fff;
    }
    h2 {
      font-size: 18px;
    }
  }
  .red-border {
    border: 1px solid red !important;
    transition: border-color 0.3s ease-in-out !important;
  }
  .checkins {
    padding: 0 25px;
    width: 100%;
    height: calc(100vh - 185px);
    overflow: scroll;
    .mood {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #f4f4f4;
      gap: 50px;
      h3 {
        font-size: 16px;
        width: max-content;
        text-wrap: nowrap;
      }
      .mood-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        border: 1px solid transparent;
        border-radius: 12px;
        transition: border-color 0.3s ease-in-out;
        .mood-btn {
          width: 75px;
          height: 75px;
          &.checked {
            background-color: #dadada;
          }
        }
      }
    }
    .suggestions {
      padding: 15px 0;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f4f4f4;
      .green {
        background-color: #44c596;
      }
      .red {
        background-color: #fc8674;
      }
      .blue {
        background-color: #5dcecb;
      }
      .column {
        width: 30%;
        .suggestion-title-line {
          justify-content: space-between;
          display: flex;
          margin-bottom: 25px;
          .dot {
            border-radius: 50%;
            width: 12px;
            height: 12px;
          }
          .suggestion-title {
            font-size: 14px;
            font-weight: bold;
          }
          svg {
            rotate: 90deg;
          }
        }
        ul {
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
          li {
            display: flex;
            width: 100%;
            padding: 8px;
            border-radius: 6px;
            justify-content: space-between;
            text-align: left;
            .actions {
              display: flex;
              flex-direction: column;
              gap: 5px;
              align-items: center;
              justify-content: center;
              .action-btn {
                visibility: hidden;
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
            }
            &:hover {
              .action-btn {
                visibility: visible;
                &.hidden {
                  visibility: hidden;
                }
              }
            }
            .add-btn {
              width: 100%;
              height: 100%;
              background-color: #f4f4f4;
              height: 62px;
              font-size: 12px;
              color: #666666;
              padding: 0;
              justify-content: start;
              padding: 17px 23px;
              svg {
                width: 28px;
                height: 28px;
                path {
                  stroke: #666;
                }
              }
            }
            textarea {
              width: 100%;
              height: 100%;
              margin-bottom: 0;
              resize: none;
              font-size: 12px;
              padding: 8px;
            }
            .link-actions {
              .link {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .feedback {
      padding: 15px 25px;
      border-bottom: 1px solid #f4f4f4;
      h3 {
        font-size: 16px;
        margin-bottom: 25px;
      }
    }
    .charts {
      padding: 15px 0;
      .title-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .filter {
          .filters-container {
            z-index: 10;
          }
        }
      }
      .chart {
        display: flex;
        gap: 15px;
        max-width: calc(100vw - 350px);
        .select-team-title {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: -1.6em;
        }

        .line-chart {
          width: 60%;
          .chart-filters {
            display: flex;
            justify-content: space-between;
            padding: 6px 0;
            font-size: 10px;
            .single-filter {
              padding: 2px 15px;
              cursor: pointer;
              border-radius: 12px;
              &.selected {
                color: #fff;
              }
            }
          }
          .line-chart-wrapper {
            position: relative;
            .chart-labels {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 17px;
              left: 7px;
              height: 100%;
              padding: 1em 0 2.8em 0;
              justify-content: space-between;
              .icon-wrapper {
                height: 30px;
                width: 30px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
        .dot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }
        .doughnut-chart {
          width: 40%;
          display: flex;
          flex-direction: column;
          padding-bottom: 30px;
          ::-webkit-scrollbar {
            display: block;
            -webkit-appearance: none;
            height: 5px;
            width: 7px;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #f2f2f2;
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
          .title-line {
            .title {
              font-weight: normal;
            }
          }
          .chart-wrapper {
            display: flex;
            gap: 45px;
            justify-content: space-between;
            flex-grow: 1;
            .avg {
              font-size: 12px;
              position: relative;
              min-width: 150px;
              .list-title {
                margin-bottom: 15px;
              }
              .avg-list {
                width: max-content;
                display: flex;
                flex-direction: column;
                gap: 17px;
                overflow-y: scroll;
                position: absolute;
                top: 30px;
                bottom: 0;
                .filter-option {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 15px;
                  border-radius: 12px;
                  cursor: pointer;
                  border: 1px solid transparent;
                  padding: 5px 10px;
                  margin-right: 5px;
                  &.active {
                    border: 1px solid #dadada;
                  }
                  &:hover {
                    border: 1px solid #f4f4f4;
                  }
                  .filter-line {
                    display: block;
                    p.details {
                      font-size: 10px;
                      color: #969696;
                    }
                    img {
                      width: 12px;
                      height: 12px;
                    }
                  }
                  .icon-wrapper {
                    width: 25px;
                    height: 25px;
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
          .current-avg {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #dadada;
            padding: 10px 10px 10px 24px;
            margin-top: 35px;
            .icon-wrapper {
              width: 36px;
              height: 36px;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .doughnut-wrapper {
          position: relative;
          flex-grow: 1;
          width: 100px;
          .legend {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            flex-wrap: wrap;
            .legend-item {
              display: flex;
              align-items: center;
              gap: 5px;
              img {
                width: 20px;
                height: 20px;
              }
              .legend-color {
                width: 20px;
                height: 10px;
              }
            }
          }
          .barchart-wrapper {
            position: absolute;
            pointer-events: none;
            background-color: #fff;
            border-radius: 12px;
            border: 1px solid #dadada;
            padding: 5px;
            padding-bottom: 30px;
            transform: translate(-105%, -50%);
            width: 100%;
            z-index: 99;
            .bar-title {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 25px;
                width: 25px;
              }
            }
          }
        }
        .teams-chart {
          width: 40%;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .doughnut-chart {
            width: 100%;
            flex-grow: 1;
          }
          .search-wrapper {
            input {
              height: 28px;
              padding: 2px 2px 2px 35px;
              width: 30%;
              margin: 0 0 0 auto;
            }
            svg {
              width: 12px;
              height: 12px;
              right: 28%;
              path {
                stroke: #666;
              }
            }
            .typeahead-search {
              width: 30%;
              margin: 0 0 0 auto;
            }
            .typeahead-selected {
              flex-direction: row;
              justify-content: flex-start;
              gap: 30px;
              margin-bottom: 0;
              .typeahead-selected-item {
                gap: 10px;
                &::before {
                  content: "";
                  width: 18px;
                  height: 18px;
                  // background-color: red;
                  border-radius: 50%;
                }
                .typeahead-selected-remove {
                  border-radius: 50%;
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  svg {
                    display: none;
                  }
                  &::before {
                    content: "";
                    color: #666;
                    width: 8px;
                    border-top: 1px solid #666;
                  }
                }
              }
            }
          }
          .selected-teams {
            display: grid;
            grid-template-columns: auto auto auto;
            row-gap: 30px;
            .selected-team {
              display: flex;
              align-items: center;
              p {
                padding-left: 12px;
              }
            }
          }
        }
      }
    }
  }
  .history-offcanvas {
    background-color: #fff;
    .check-in-history {
      padding: 30px;
      border-left: 1px solid #f4f4f4;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        h3 {
          margin-bottom: 0;
        }
      }
      .history {
        .history-item {
          width: 100%;
          border-radius: 6px;
          border: none;
          padding: 10px;
          cursor: pointer;
          &:hover {
            border: 2px solid #f4f4f4;
          }
          h3 {
            margin-bottom: 0;
          }
          h4 {
            font-size: 16px;
            margin: auto 0;
          }
          .icon-wrapper {
            height: 35px;
            width: 35px;
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
}
.typeahead-selected-item {
  &.pastel-purple {
    &::before {
      background-color: #d9b8ff;
    }
  }
  &.amber {
    &::before {
      background-color: #ffd966;
    }
  }
  &.mint-green {
    &::before {
      background-color: #85e0a3;
    }
  }
  &.sky-blue {
    &::before {
      background-color: #80caff;
    }
  }
  &.soft-coral {
    &::before {
      background-color: #e27d7d;
    }
  }
  &.pastel-pink {
    &::before {
      background-color: #ef7dd6;
    }
  }
  &.rich-plum {
    &::before {
      background-color: #622ab2;
    }
  }
  &.golden-brown {
    &::before {
      background-color: #be8628;
    }
  }
  &.soft-sage {
    &::before {
      background-color: #26b489;
    }
  }
  &.dusk-blue {
    &::before {
      background-color: #2f5ab6;
    }
  }
  &.burnt-orange {
    &::before {
      background-color: #be3939;
    }
  }
  &.lavender {
    &::before {
      background-color: #ca3cab;
    }
  }
  &.mocha {
    &::before {
      background-color: #a2845e;
    }
  }
  &.dark-gray {
    &::before {
      background-color: #884848;
    }
  }
  &.forest-green {
    &::before {
      background-color: #578848;
    }
  }
  &.steel-blue {
    &::before {
      background-color: #487588;
    }
  }
  &.deep-plum {
    &::before {
      background-color: #884884;
    }
  }
  &.olive-green {
    &::before {
      background-color: #818600;
    }
  }
}
