$borderColor: #f4f4f4;
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column {
  width: 25%;
  text-align: center;
  &:first-child {
    text-align: left;
  }
}
.activity-item {
  border: 1px solid $borderColor;
  border-radius: 15px;
  padding: 13px 13px 13px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  overflow: hidden;
  transition: min-height 0.3s ease-in-out;
  .row {
    .column {
      .column-title {
        font-weight: bold;
      }
    }
    .actions {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      // margin-right: 40px;
      .drop-btn {
        display: flex;
        gap: 5px;
        // position: absolute;
        // bottom: 13px;
        // right: 13px;
        height: 30px;
        .vertical-line {
          width: 1px;
          background-color: $borderColor;
        }
        svg {
          transition: transform 0.3s ease-in-out;
        }
      }
      // .expanded-btn {
      //   svg {
      //     transform: rotate(540deg);
      //   }
      // }
    }
    .expanded {
      margin-right: 40px;
      .drop-btn {
        position: absolute;
        bottom: 13px;
        right: 13px;
      }
      .expanded-btn {
        svg {
          transform: rotate(540deg);
        }
      }
    }
  }
  .activity-data.row {
    align-items: start;
    color: #6a6a6a;
    .column-title {
      color: #0f2a3c;
      margin-bottom: 5px;
    }
    .text-data {
      width: 90%;
      height: 100%;
      padding: 8px 8px 8px 13px;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      background-color: #f5f5f5;
      color: #6a6565;
      white-space: pre-wrap;
    }
    .column-spread-2 {
      width: 50%;
    }
  }
}
