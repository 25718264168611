.form-wrapper {
  .form-group {
    label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      color: #6a6a6a;
    }
  }
  .form-row {
    display: flex;
    gap: 20px;
    .form-group {
      width: 50%;
    }
  }

  .bottom-margin {
    margin-bottom: 24px;
  }
}
