.timeline-wrapper {
  .title-legend {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .title {
      font-weight: bold;
      color: #0f2a3c;
      font-size: 14px;
    }

    .legend {
      display: flex;
      gap: 35px;
      color: #666;
      align-items: center;

      .legend-item {
        .point-wrapper {
          display: flex;
          gap: 8px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .timeline {
    display: flex;
    flex-direction: row;
    width: 100%;

    .timeline-month {
      width: 100%;
      display: flex;
      flex-direction: column;

      .month {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 220px;
        border-bottom: 1px solid;
        border-top: 1px solid;
        position: relative;

        .horizontal-line {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;

          hr {
            width: 100%;
            margin: 0;
            opacity: 1;
          }

          .point-wrapper {
            position: absolute;
            z-index: 1;
          }
        }

        .point-details {
          position: absolute;
          height: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #5e7c8f;

          .point-title {
            width: min-content;
            min-width: 70px;
            height: 50%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            font-size: 12px;
          }

          .point-description {
            display: flex;
            align-items: center;
            height: 50%;
            width: min-content;
            min-width: 70px;
            text-align: center;
            font-size: 10px;
            .vertical-line {
              display: inline-table;
              // height: 35px;
              height: 100%;
              width: 1px;
              background-color: black;
            }
            .dot {
              width: 5px;
              height: 5px;
              min-width: 5px;
              max-width: 5px;
              min-height: 5px;
              max-height: 5px;
              border-radius: 50%;
              background-color: black;
            }
          }
        }
      }

      &:first-of-type .month {
        padding-left: 5px;
        border-left: 1px solid;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &:last-of-type .month {
        padding-right: 5px;
        border-right: 1px solid;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      label {
        align-self: center;
        margin-top: 8px;
        font-size: 12px;
        color: #666;
      }
    }
  }
}

.point-wrapper {
  align-items: center;

  .point {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    min-width: 22px;
    max-width: 22px;
    height: 22px;
    min-height: 22px;
    max-height: 22px;
    border-radius: 50%;
    border: 2px solid;

    div {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

  &[data-legend="false"] {
    .point {
      cursor: pointer;
    }
  }
}

.flex-column {
  flex-direction: column;

  .point-title {
    padding-top: 0;
    padding-bottom: 12px;
    align-items: end;
  }

  .point-description {
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: start;
  }
}

.flex-column-reversed {
  flex-direction: column-reverse;

  .point-title {
    padding-top: 12px;
    padding-bottom: 0;
    align-items: start;
  }

  .point-description {
    flex-direction: column-reverse;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: start;
  }
}
