$borderColor: #f4f4f4;

.single-view {
  .meetings-review {
    display: flex;
    gap: 40px;
    .meetings {
      width: 70%;
      .one-on-one {
        .title-line {
          button {
            gap: 13px;
            svg {
              rotate: 270deg;
            }
          }
        }
        .one-on-one-list {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: fit-content;
          max-height: 18em;
          overflow: scroll;
          padding: 0;
          li {
            display: flex;
            padding: 13px 13px 13px 24px;
            border: 1px solid $borderColor;
            border-radius: 15px;
            align-items: center;
            justify-content: space-between;
            .column {
              width: 25%;
              display: flex;
              justify-content: center;
              &:first-child {
                flex-direction: column;
              }
              &:last-child {
                justify-content: end;
              }
              .name {
                font-weight: bold;
              }
              .not-scheduled {
                padding: 5px 15px;
                background-color: #f5f5f5;
                color: #666;
                border-radius: 40px;
              }
            }
          }
        }
      }
      .meetings-attendance {
        .cards {
          display: flex;
          justify-content: space-between;
          .card-filter {
            cursor: default;
            width: 20%;
            svg {
              width: 22px;
              height: 22px;
            }
            &:nth-child(3) {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
    .chart {
      width: 30%;
    }
  }
  .conference {
    display: flex;
    gap: 40px;
    .title-line {
      button {
        gap: 13px;
        svg {
          width: 16px;
          height: 16px;
          path {
            stroke: #666;
          }
        }
      }
    }
    h4 {
      font-size: 12px;
    }
    .conferences-lists {
      width: 70%;
      .activity {
        margin-bottom: 10px;
        .activity-group {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: fit-content;
          max-height: 18em;
          overflow: scroll;
          padding: 0;
        }
        // ul {
        //   font-size: 12px;
        //   display: flex;
        //   flex-direction: column;
        //   gap: 10px;
        //   height: fit-content;
        //   max-height: 18em;
        //   overflow: scroll;
        //   padding: 0;
        //   // li.expanded {
        //   //   min-height: 200px;
        //   // }
        //   li {
        //     border: 1px solid $borderColor;
        //     border-radius: 15px;
        //     padding: 13px 13px 13px 24px;
        //     display: flex;
        //     flex-direction: column;
        //     gap: 10px;
        //     position: relative;
        //     // min-height: 61px;
        //     // max-height: 61px;
        //     // transition: min-height 0.3s ease-in-out;
        //     // height: 61px;
        //     // overflow: hidden;

        //     .row {
        //       display: flex;
        //       justify-content: space-between;
        //       align-items: center;
        //     }
        //     .clmn {
        //       width: 25%;
        //       text-align: center;
        //       &:first-child {
        //         text-align: left;
        //       }
        //     }
        //     .activity-title {
        //       font-weight: bold;
        //     }
        //     .actions {
        //       display: flex;
        //       gap: 8px;
        //       justify-content: flex-end;
        //       margin-right: 40px;
        //       .drop-btn {
        //         display: flex;
        //         gap: 5px;
        //         position: absolute;
        //         bottom: 13px;
        //         right: 13px;
        //         height: 30px;
        //         .vertical-line {
        //           width: 1px;
        //           background-color: $borderColor;
        //         }
        //         svg {
        //           transition: transform 0.3s ease-in-out;
        //         }
        //       }
        //       .drop-btn.expanded {
        //         svg {
        //           transform: rotate(540deg);
        //         }
        //       }
        //     }
        //     .activity-item-drop {
        //       display: flex;
        //       height: fit-content;
        //       justify-content: space-between;
        //       width: 100%;
        //       .clmn {
        //         width: 25%;
        //         text-align: center;
        //         color: #6a6a6a;
        //         &:first-child {
        //           text-align: left;
        //         }
        //         &:last-child {
        //           display: flex;
        //           align-items: end;
        //           justify-content: end;
        //           svg {
        //             rotate: 180deg;
        //           }
        //         }
        //         .column-title {
        //           color: #0f2a3c;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
    .high-fives {
      width: 30%;
      font-size: 12px;
      .high-fives-list {
        border: 1px solid $borderColor;
        border-radius: 24px;
        overflow: scroll;
        padding: 20px;

        ul {
          padding: 0;
          li {
            margin-bottom: 10px;
            .acter {
              font-weight: bold;
            }
          }
        }
        .high-fives-items {
          li {
            display: flex;
            gap: 10px;
            .overlap-images {
              display: flex;
              .user-icon {
                margin-left: -5px;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
