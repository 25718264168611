.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 2px solid #fff;
    outline: 1px solid #5f6368;
    cursor: pointer;
    padding: 2px;
  }
  input[type="radio"]:checked {
    background-color: #5f6368;
  }
  input[type="checkbox"] {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 3px;
    border: 1px solid #5f6368;
    cursor: pointer;
    padding: 2px;
  }
  input[type="checkbox"]:checked {
    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 2px;
      width: 11px;
      height: 6px;
      border-bottom: 2px solid #6a6565;
      border-left: 2px solid #6a6565;
      transform: rotate(-45deg);
    }
  }
}
