.non-respondents {
    padding: 30px 40px;

    .date{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .count{
        margin-top: 10px;
    }
}