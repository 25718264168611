.meetings-form-wrapper {
  padding: 30px 60px;
  max-height: calc(100vh - 90px);
  overflow-y: scroll;
  .meetings-form-header {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .meetings-form-body {
    padding: 20px 40px;
    border: 1px solid #f4f4f4;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
    .form-group {
      label {
        margin-bottom: 10px;
        font-size: 14px;
        color: #6a6a6a;
      }
      .input-wrapper {
        input {
          margin: 0;
        }
      }
    }
    .checkbox-wrapper {
      label {
        margin: 0;
      }
    }
    .form-group {
      .input-icon-wrapper {
        .dark-form-group {
          .rbt-input-main {
            background-color: #f5f5f5;
            border: 1px solid #e1e1e1;
            font-size: 14px;
            line-height: 1.5;
            margin: 0;
            min-height: 48px;
            padding: 14px 20px 14px 50px;
          }
        }
      }
      .input-icon-wrapper {
        position: relative;
        svg {
          position: absolute;
          left: 20px;
          z-index: 2;
          top: 16px;
        }
        .dark-form-group {
          .form-control {
            background-color: transparent;
            border: none;
            margin-top: 0;
            max-height: none;
            padding: 0;

            .rbt-input-wrapper {
              display: flex;
              flex-basis: 100%;
              flex-direction: column;
              gap: 16px;
              .rbt-input-main {
                border: 1px solid #e1e1e1 !important;
                padding: 14px 20px 14px 50px !important;
                background-color: #f5f5f5 !important;
                font-size: 14px;
                min-height: 48px;
                margin: 0;
                line-height: 1.5;
                border-radius: 6px;
              }
              .rbt-token-removeable {
                border: none;
                color: #0f2a3c;
                display: flex;
                font-size: 14px;
                font-weight: 600;
                justify-content: space-between;
                max-width: 100%;
                order: 2;
                .btn-close {
                  align-items: center;
                  background: #0000;
                  border: 1px solid #a1a1a1;
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    .date-picker-holder {
      .input-icon-wrapper {
        svg {
          top: 12px;
          z-index: 1;
        }
        input {
          padding: 14px 10px 14px 55px;
          background-color: #f5f5f5;
        }
      }
    }
    .meetings-text {
      font-size: 14px;
      color: #6a6a6a;
      position: relative;
      margin-bottom: 20px;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
      input {
        cursor: pointer;
        left: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
      }
    }
    textarea {
      background-color: #f5f5f5;
      display: flex;
      margin-top: 0;
      padding: 14px 20px;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      font-size: 14px;
    }
    .select__control {
      background-color: #f5f5f5;
      border: 1px solid #e1e1e1;
      box-shadow: none;
      font-size: 14px;
      max-height: none;
      padding: 5px 20px;
    }
  }

  .offcanvas-files-list {
    margin-top: 16px;
    list-style: none;
    padding: 0;
    li {
      padding: 8px;
      margin-bottom: 8px;
      background-color: #f5f5f5;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        text-decoration: none;
      }
      span {
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
    svg {
      width: 10px;
      cursor: pointer;
      path {
        fill: #737373;
      }
    }
  }
}

// .select-wrapper {
//   display: flex;
//   flex-direction: column;
// }

// label {
//   font-size: 14px;
// }
// .meetings-offcanvas {
//   max-height: 90% !important;
//   min-width: 500px;
//   background-color: #fff;
// }

// .select-wrapper select {
//   padding: 14px 15px;
//   background-color: #f5f5f5;
//   border: 1px solid #e1e1e1;
//   border-radius: 6px;
// }

// .select-wrapper select option {
//   font-size: 14px;
// }

// .select-wrapper select:focus-visible {
//   border: 1px solid #000;
//   outline: none;
// }

// .multiselect-dropdown .form-control {
//   background: transparent;
//   border: none;
//   padding: 0;
//   margin-top: 0;
//   max-height: initial;
// }

// .multiselect-dropdown .form-control .rbt-input-wrapper {
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
//   flex-basis: 100%;
// }

// .multiselect-dropdown .rbt-token-removeable {
//   order: 2;
//   max-width: 100%;
//   display: flex;
//   justify-content: space-between;
//   border: none;
//   color: #0f2a3c;
//   font-weight: 600;
//   font-size: 14px;
// }

// .multiselect-dropdown .rbt-input-main {
//   border: 1px solid #e1e1e1 !important;
//   padding: 14px 20px 14px 50px !important;
//   background-color: #f5f5f5 !important;
//   font-size: 14px;
//   min-height: 48px;
//   margin: 0;
//   line-height: 1.5;
// }

// .multiselect-dropdown .btn-close {
//   background: transparent;
//   border: 1px solid #a1a1a1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .multiselect-dropdown .btn-close:focus {
//   box-shadow: none;
// }

// .input-icon-wrapper {
//   position: relative;
// }
// .input-icon-wrapper svg {
//   position: absolute;
//   left: 20px;
//   z-index: 2;
//   top: 16px;
// }

// .date-picker-holder .input-icon-wrapper svg {
//   z-index: 1;
// }

// .multiselect-dropdown .btn-close:focus-visible {
//   outline: none;
// }

// .multiselect-dropdown .btn-close span {
//   color: #a1a1a1;
//   font-size: 16px;
//   font-weight: bold;
// }

// .date-picker-holder .react-datepicker-time__input {
//   margin: 0;
//   padding: 5px 10px;
// }

// .date-picker-holder .input-icon-wrapper svg {
//   top: 13px;
// }

// ._offcanvas input {
//   padding: 18px 20px;
//   background-color: #f5f5f5;
//   border-radius: 6px;
//   border: 1px solid #e1e1e1;
//   font-size: 14px;
//   margin-top: 0;
// }

// ._offcanvas .input-icon-wrapper input {
//   padding: 14px 10px 14px 55px;
// }

// ._offcanvas {
//   bottom: 14px !important;
//   border: 1px solid #f6f6f6 !important;
//   max-width: 500px;
// }

// ._offcanvas ._offcanvas-header {
//   display: flex;
//   align-items: center;
//   padding: 1rem 1rem;
//   background-color: #0f2a3c;
//   color: #fff;
//   justify-content: space-between;
// }

// ._offcanvas ._offcanvas-header ._offcanvas-title {
//   font-size: 14px;
//   font-weight: 600;
// }

// ._offcanvas-text {
//   margin-bottom: 24px;
//   font-size: 14px;
//   color: #6a6a6a;
// }

// ._offcanvas-text span {
//   font-size: 14px;
//   color: #6a6a6a;
//   text-decoration: underline;
// }

// ._offcanvas-text input[type="file"]::-webkit-file-upload-button {
//   visibility: hidden;
// }

// ._offcanvas-text input[type="file"]::before {
//   content: "";
// }

// ._offcanvas-text input[type="file"]:not(:empty)::before {
//   content: "File selected";
// }
// ._offcanvas-text input[type="file"] {
//   opacity: 0;
//   position: absolute;
//   left: 0;
//   padding: 0;
//   cursor: pointer;
// }

// ._offcanvas-footer {
//   display: flex;
//   justify-content: flex-end;
//   border-top: 1px solid #f1f1f1;
//   padding: 24px 0;
//   margin-top: 24px;
// }

// ._offcanvas-footer button.btn-primary {
//   display: flex;
//   align-items: center;
//   grid-gap: 10px;
//   gap: 10px;
//   background-color: #0f2a3c;
//   border: none;
//   font-size: 14px;
//   padding: 18px 30px;
//   border-radius: 6px !important;
//   margin: 0;
// }

// ._offcanvas-footer button.btn-primary span {
//   color: #fff;
// }

// .date-time-wrapper-half {
//   display: flex;
//   justify-content: space-between;
//   gap: 16px;
// }

// .date-time-wrapper-half .half-wrapper {
//   flex-basis: 50%;
// }

// ._offcanvas-body {
//   max-height: 85vh;
//   overflow-y: scroll;
//   padding: 1rem 1rem;
//   flex-grow: 1;
//   text-align: left;
// }

// .form-group._offcanvas-body textarea {
//   max-height: inherit;
// }

// .offcanvas-files-list {
//   margin-top: 16px;
//   list-style: none;
//   padding: 0;
// }

// .offcanvas-files-list li {
//   padding: 8px;
//   margin-bottom: 8px;
//   background-color: #f5f5f5;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .offcanvas-files-list li a {
//   text-decoration: none;
// }

// .offcanvas-files-list li span {
//   text-decoration: none;
//   display: flex;
//   align-items: center;
// }

// .offcanvas-files-list svg {
//   width: 10px;
//   cursor: pointer;
// }

// .offcanvas-files-list svg path {
//   fill: #737373;
// }
