.header-feedback {
  .outside-click {
    width: auto !important;
    .dropdown-selected {
      min-height: 56px;
    }
    .dropdown-list {
      max-height: calc(4 * 56px);
    }
  }
}
.feedback-list-wrapper {
  margin-bottom: 20px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .feedback-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 180px);
    overflow: scroll;
    li {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid #f4f4f4;
      border-radius: 10px;
      padding: 24px;
      margin-bottom: 20px;
      cursor: pointer;
      b {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        flex-basis: 35%;
      }
      .feedback-message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-basis: 35%;
        b {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }
      span {
        flex-basis: 20%;
      }
      p {
        margin: 0;
        flex-basis: 20%;
        color: #0f2a3c;
      }
    }
  }
  .empty-list-message {
    margin-bottom: 30px;
    padding: 0 15px;
    border-radius: 6px;
    font-size: 14px;
  }
}

.career-path-wrapper {
  .status-wrapper {
    flex-basis: 25%;
    text-align: right;
    .status {
      background-color: rgba(0, 223, 165, 0.13);
      color: #00b083;
      border-radius: 40px;
      padding: 4px 16px;
      font-size: 14px;
    }
    .status.purple {
      background-color: rgba(139, 92, 246, 0.2);
      color: #8b5cf6;
    }
    .status.blue {
      background-color: #cfd9fb;
      color: #1040e9;
    }
  }
  .status-details {
    flex-basis: initial;
  }
}

.feedback-form-header {
  border-bottom: 1px solid #f4f4f4;
  .header-title {
    display: block;
    p {
      display: block;
      font-size: 14px;
      color: #a1a1a1;
      margin-bottom: 6px;
      cursor: pointer;
    }
    h2 {
      display: flex;
      span {
        display: inline;
        font-size: 14px;
        font-weight: 300;
        margin-left: 8px;
      }
    }
  }
}

.career-feedback-grid {
  display: grid;
  grid-template-columns: 65% 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "employee feedback"
    "details feedback"
    "feedbacks feedback";
  gap: 25px;
  padding: 25px;
  max-height: calc(100vh - 180px);
  overflow: scroll;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  .employee-section {
    grid-area: employee;
    border: 1px solid #f5f5f5;
    border-radius: 12px;
  }
  .feedback-section {
    grid-area: feedback;

    .feedback-wrapper {
      border: 1px solid #f5f5f5;
      border-radius: 12px;
      padding: 24px;
      p {
        margin: 8px 0 20px;
        font-size: 14px;
      }
      label {
        color: #666;
      }
      textarea {
        font-size: 14px;
      }
      .feedback-action {
        display: flex;
        justify-content: flex-end;
        button {
          background-color: #0f2a3c;
          width: 40%;
          min-width: 80px;
        }
      }
    }
  }
  .task-section {
    grid-area: details;
    .task-section-wrapper {
      border: 1px solid #f5f5f5;
      border-radius: 12px;
    }
  }
  .task-feedbacks-section {
    grid-area: feedbacks;
    border: 1px solid #f5f5f5;
    border-radius: 12px;
    .feedback-user {
      margin: 16px 0;
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        width: 32px;
        border-radius: 50%;
      }
      span {
        display: block;
        color: #6a6565;
        font-size: 14px;
      }
    }
    .selected-files {
      max-width: max-content;
    }
  }
  .details-item {
    padding: 22px 20px;
    border-bottom: 1px solid #f5f5f5;
    .feedback-date-time {
      span {
        display: block;
        color: #666;
        font-size: 12px;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .flex-block {
    display: flex;
    align-items: flex-start;
    label {
      min-width: 170px;
      font-size: 12px;
      color: #666;
    }
    p,
    span {
      margin: 0;
      font-size: 14px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      a {
        font-size: 14px;
      }
    }
    .file-wrapper {
      span {
        margin-left: 20px;
      }
    }
  }
}
