.Meetings {
  text-align: center;
  flex: 1;
  overflow-x: scroll;
  padding-bottom: 10em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
textarea {
  min-height: 5em;
  width: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="datetime-local"] {
  position: relative;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.rbt-token {
  display: flex;
  border: 1px solid black;
  max-width: fit-content;
}

.meetings-wrapper .input-group {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 23px 30px;
}

.meetings-wrapper .btn-primary {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #0f2a3c;
  border: none;
  font-size: 14px;
  padding: 18px 30px;
  color: #fff;
  border-radius: 6px !important;
  margin: 0;
}

.meetings-wrapper .btn-primary:hover {
  background-color: #0f2a3c;
  color: #fff;
}

.meetings-wrapper .btn-primary span {
  color: #fff;
}

.calendar-control {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
  }
  button {
    min-width: 25px;
    min-height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    &:after {
      content: "";
      display: block;
      margin-left: 3px;
      width: 9px;
      height: 9px;
      border-bottom: 2px solid #a1a1a1;
      border-right: 2px solid #a1a1a1;
      transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
}

.calendar-control .control-next::after {
  margin-left: 0;
  margin-right: 5px;
  transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.meetings-main {
  display: flex;
  gap: 24px;
  padding: 30px;
}

.calendar-wrapper {
  flex-grow: 1;
  padding: 24px;
  border: 1px solid #f6f6f6;
}

.event-users {
  display: flex;
  gap: 6px;
  img {
    width: 20px;
    height: 20px;
  }
}

.custom-event-class {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
