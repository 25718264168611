.users-row-wrapper {
  .users-row {
    display: flex;
    gap: 4px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    .user-icon {
      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
    }
    .user-count {
      background-color: #f1f1f1;
      color: #0f2a3c;
      min-width: 28px;
      height: 28px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .user-full-name {
    display: none;
    background-color: #fff;
    padding: 10px;
    margin: 0;
    border-radius: 6px;
    font-size: 14px;
  }
  &:hover {
    .user-full-name {
      display: block;
      position: absolute;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }
  }
}
