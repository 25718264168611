* {
  font-family: "Noto Sans", sans-serif;
}

body {
  --slack-color: #0f2a3c;
  overflow: hidden;
}

.App {
  display: flex;
  min-height: 100vh;
}

.app-body {
  flex-grow: 1;
}

.scrollable-content {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}

.information {
  margin: 15px 10px;
}

.information > p {
  margin-top: 10px;
  font-size: 15px;
}

.notification-holder {
  margin-top: 5em;
}

.toast-body {
  color: white !important;
  font-weight: bolder !important;
  background-color: #110f40 !important;
}
.toastClass {
  position: top-right;
  border: 1px solid #110f40;
  padding: 16px;
  color: #ffffff;
  background: #110f40;
  margin: 10px 0;
}
.toast {
  // cursor: pointer !important;
  // margin-top: 100px !important;
}

input:focus {
  border: 1px solid #0f2a3c;
  box-shadow: none !important;
}

.sidebar a {
  text-decoration: none;
  color: #fff;
}

.table-wrapper {
  padding: 20px 30px;
  @media (max-width: 375px) {
    padding: 20px;
  }
}

.table-wrapper h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 18px;
}

.table-wrapper table {
  border: 1px solid #f5f5f5;
}

.table-wrapper thead tr th {
  font-size: 12px;
  color: #0f2a3c;
  font-weight: 800;
  padding: 12px 20px;
  vertical-align: middle;
}

.table-wrapper table td {
  font-size: 14px;
  padding: 12px 20px;
  vertical-align: middle;
}

.modal-dialog {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.modal-dialog .modal-content {
  border-radius: unset;
}

.modal-content .modal-header {
  justify-content: space-between;
  background-color: #0f2a3c;
  color: #fff;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.modal-header .close-btn {
  cursor: pointer;
}

.modal-header .modal-title {
  font-size: 14px;
  font-weight: 600;
}
.modal-content .modal-body {
  padding: 24px;
}
.modal-body:after {
  content: "";
  background-color: #f1f1f1;
  height: 1px;
  position: absolute;
  left: 24px;
  right: 24px;
}

.modal-body label {
  color: #6a6a6a;
  font-size: 14px;
  margin: 0;
  display: block;
}

.modal-body input,
.modal-body textarea {
  padding: 18px 20px;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  margin: 8px 0 24px 0;
  font-size: 14px;
}

.modal-body input {
  padding: 14px 20px;
  max-height: 48px;
}
.modal-body textarea {
  max-height: initial;
}
.modal-content .modal-footer {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-top: none;
}

.modal-footer .btn,
.tab-content .btn,
.table-wrapper .btn-primary,
.career-header .btn-primary,
.buttons-wrapper .btn-primary {
  padding: 18px 30px;
  border-radius: 6px !important;
}

.modal-footer .btn-primary,
.tab-content .btn-primary,
.table-wrapper .btn-primary,
.career-header .btn-primary,
.buttons-wrapper .btn-primary {
  background-color: #0f2a3c;
  border: none;
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 18px;
}

.modal-footer .btn-primary:disabled,
.tab-content .btn-primary:disabled,
.table-wrapper .btn-primary:disabled,
.buttons-wrapper .btn-primary:disabled {
  cursor: default;
  background-color: #0f2a3c;
}

.table-wrapper .btn-primary span,
.career-header .btn-primary span {
  color: #fff;
}

.modal-content .modal-footer .btn-primary:hover,
.modal-content .modal-footer .btn-primary:active,
.buttons-wrapper .btn-primary:hover,
.buttons-wrapper .btn-primary:active,
.table-wrapper .btn-primary:hover,
.table-wrapper .btn-primary:active,
.career-header .btn-primary:hover,
.career-header .btn-primary:active {
  background-color: #0f2a3c;
}

.modal-footer .btn-secondary,
.buttons-wrapper .btn-secondary {
  background-color: transparent;
  color: #0f2a3c;
  border: none;
  font-size: 14px;
}
.modal-content .modal-footer .btn-secondary:hover,
.modal-content .modal-footer .btn-secondary:active,
.buttons-wrapper .btn-secondary:hover,
.buttons-wrapper .btn-secondary:active {
  background-color: transparent;
  color: #0f2a3c;
}

/* Pagination */
.pagination {
  margin-top: 42px;
}
.table-wrapper .active > .page-link {
  background-color: #0f2a3c;
  border: none;
  color: white;
}

.table-wrapper .page-link {
  background-color: #f5f5f5;
  margin: 0 4px;
  border-radius: 6px;
}

.table-wrapper .page-item:first-child .page-link,
.table-wrapper .page-item:last-child .page-link {
  display: flex;
  align-items: center;
  background-color: transparent;
  max-height: 36px;
}

.table-wrapper .page-item:first-child .page-link span,
.table-wrapper .page-item:last-child .page-link span {
  font-size: 30px;
  color: #666;
  padding-bottom: 7px;
}

.table-wrapper .page-link,
.table-wrapper .page-link span {
  color: #0f2a3c;
  border: none;
}

.table-wrapper .page-item:focus-visible {
  outline: none;
}

.table-wrapper .page-link:focus,
.table-wrapper .page-link:hover {
  outline: none;
  color: #0f2a3c;
  box-shadow: none;
}

.table-wrapper .active > .page-link:hover {
  color: #fff;
  background-color: #0f2a3c;
  border: none;
}

/* Modal Inputs */

.form-group .form-control,
.form-group .basic-multi-select .select__control {
  background-color: #f5f5f5;
  padding: 14px 20px;
  margin-top: 0;
  display: flex;
  align-content: center;
}

.select__menu div {
  background-color: #f5f5f5;
  color: #6a6565;
}

.select__menu div:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.form-group .form-control {
  max-height: 48px;
  font-size: 14px;
}

.form-group .basic-multi-select {
  margin: 8px 0 24px;
}

.form-group {
  input[type="input"]:disabled {
    color: #b2afaf;
    background-color: #f5f5f5;
    &::placeholder {
      color: #b2afaf;
    }
  }
}

textarea.form-control:focus-visible {
  border: 1px solid #0f2a3c;
  box-shadow: none;
}

.modal-content textarea.form-control {
  max-height: initial;
}
.form-group .basic-multi-select .select__multi-value {
  background-color: #fff;
  border-radius: 40px;
  padding: 4px 16px;
  font-size: 14px;
}
.modal.show {
  overflow: hidden;
}

.modal.show .modal-dialog {
  margin-top: 0;
}
.form-group .form-label {
  margin-bottom: 8px;
}

.modal-content .modal-body {
  max-height: calc(100vh - 250px);
  overflow: scroll;
}

.modal-content .modal-footer {
  padding-top: 0;
}

.modal-header-close {
  cursor: pointer;
}

/* Search input */

.table-search {
  position: relative;
}

// .table-search svg {
//   position: absolute;
//   left: 20px;
//   top: 20px;
// }

.table-search input {
  padding: 17px 17px 17px 53px;
  font-size: 14px;
  margin: 0;
  border: 1px solid #e1e1e1;
}
/* Date picker */

.date-picker-holder {
  position: relative;
}

.date-picker-holder {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.date-picker-holder svg {
  position: absolute;
  right: 20px;
  top: 18px;
}

.date-picker-holder .react-datepicker__input-container input {
  border: 1px solid #e1e1e1;
  padding: 14px 20px;
  border-radius: 6px;
  font-size: 14px;
  min-width: 225px;
  max-height: 48px;
}

.date-picker-holder .react-datepicker__input-container input:focus-visible {
  outline: none;
}

.date-picker-holder .react-datepicker {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 5px;
}

.date-picker-holder .react-datepicker .react-datepicker__triangle,
.date-picker-holder .react-datepicker .react-datepicker__current-month {
  display: none;
}
.date-picker-holder .react-datepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.date-picker-holder .react-datepicker .react-datepicker__navigation--next {
  right: 25px;
  left: auto;
}

.date-picker-holder .react-datepicker .react-datepicker__navigation--previous {
  right: 70px;
  left: auto;
}

.date-picker-holder .react-datepicker .react-datepicker__navigation--previous,
.date-picker-holder .react-datepicker .react-datepicker__navigation--next {
  top: 15px;
}

.date-picker-holder
  .react-datepicker
  .react-datepicker__navigation-icon::before,
.date-picker-holder
  .react-datepicker
  .react-datepicker__navigation-icon::before {
  border-color: rgba(0, 0, 0, 0.54);
  border-width: 2px 2px 0 0;
}

.react-datepicker__header__dropdown--scroll {
  text-align: left;
}

.react-datepicker__header__dropdown--scroll
  .react-datepicker__month-year-read-view--down-arrow {
  top: 2px;
  border-width: 2px 2px 0 0;
  right: -20px;
  border-color: rgba(0, 0, 0, 0.54);
}

.date-picker-holder
  .react-datepicker
  .react-datepicker__month-year-read-view--selected-month-year {
  color: #000;
}

.date-picker-holder
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__month-year-read-view {
  visibility: visible !important;
}
.date-picker-holder .react-datepicker__day-name {
  margin: 0;
  width: 35px;
  padding-top: 15px;
}

.date-picker-holder .react-datepicker__day {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 35px;
  height: 35px;
  margin: 0;
  box-sizing: content-box;
}

.date-picker-holder .react-datepicker__day:hover {
  border-radius: 50%;
}

.date-picker-holder .react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #e1e1e1;
}

.date-picker-holder .react-datepicker__day--keyboard-selected:hover {
  background-color: #0f2a3c;
  color: #fff;
}

.date-picker-holder .react-datepicker__day--in-range,
.date-picker-holder .react-datepicker__day--in-range:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #000;
  border-radius: 0;
}

.date-picker-holder .react-datepicker__day--range-start,
.date-picker-holder .react-datepicker__day--range-end {
  border-radius: 50%;
  color: #fff;
  background-color: #0f2a3c;
}

.date-picker-holder .react-datepicker__day--selected,
.date-picker-holder .react-datepicker__day--range-start:hover,
.date-picker-holder .react-datepicker__day--range-end:hover,
.date-picker-holder
  .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
  border-radius: 50%;
  color: #fff;
  background-color: #0f2a3c;
}

.date-picker-holder .react-datepicker__year-dropdown,
.date-picker-holder .react-datepicker__month-dropdown,
.date-picker-holder .react-datepicker__month-year-dropdown {
  // max-height: 250px;
  overflow: scroll;
  left: 0;
}

.react-datepicker__month-read-view--selected-month {
  visibility: visible !important;
}
.react-datepicker__year-read-view--selected-year {
  visibility: visible !important;
}

.react-datepicker__year-dropdown {
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
  }
  &::before {
    border-bottom-color: #000;
    top: 6px;
  }
  &::after {
    border-top-color: #000;
    bottom: 6px;
  }
}

.modal-content .date-picker-holder,
.modal-content .date-picker-holder input {
  width: 100%;
}

.modal-content .date-picker-holder svg {
  bottom: 39px;
  top: auto;
}

/* Select input */

.form-group .basic-multi-select .select__control {
  padding: 5px 20px;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  max-height: initial;
  font-size: 14px;
  box-shadow: none;
}

.basic-multi-select .select__control .select__value-container {
  padding: 0;
}

.basic-multi-select .select__indicator-separator {
  display: none;
}

.basic-multi-select .select__input {
  font-size: 14px;
}

.responsive-sidenav-offcanvas.offcanvas.offcanvas-start {
  max-width: 300px;
}

.responsive-sidenav-offcanvas.offcanvas.offcanvas-start .sidebar.open {
  height: 100%;
}
