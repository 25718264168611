.table-wrapper {
  padding: 30px;
  max-height: calc(100vh - 280px);
  overflow-y: scroll;
  @media (max-width: 786px) {
    width: 100vw;
    overflow-x: scroll;
    padding: 30px 0px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  table {
    min-width: 100%;
    border-collapse: collapse;
    caption-side: bottom;
    thead {
      tr {
        th {
          text-align: left;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: #f5f5f5;
        }
      }
      tr.clickable-row {
        cursor: pointer;
      }
    }
    tr {
      border-top: 1px solid #f5f5f5;
      transition: all ease 0.2s;
      .table-data-wrapper {
        display: flex;
        align-items: center;
      }
      span {
        color: #000;
        font-size: 14px;
        border-radius: 40px;
        text-align: center;
      }
      span.green {
        padding: 4px 16px;
        background-color: rgba(0, 223, 165, 0.13);
        color: #00b083;
      }
      span.red {
        padding: 4px 16px;
        background-color: #c50813;
        color: white !important;
      }
      span.yellow {
        padding: 4px 16px;
        background-color: rgba(0, 223, 165, 0.13);
        color: #00b083;
      }
      span.purple {
        padding: 4px 16px;
        background-color: rgba(139, 92, 246, 0.2);
        color: #8b5cf6;
      }
      span.blue {
        padding: 4px 16px;
        background-color: #f4f4f4;
        color: #666;
      }
      span.grey {
        padding: 4px 16px;
        background-color: #f5f5f5;
        color: #666;
      }
      span.rest {
        padding: 4px 16px;
        background-color: #f5f5f5;
        color: #666;
      }
    }
    .icon-field {
      svg {
        margin: 0 5px 2px 0;
      }
    }
    .table-actions {
      overflow: visible;
      .actions-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          padding: 7px;
          border-radius: 6px;
          width: 32px;
          height: 32px;
        }
      }
    }
    .custom-action {
      margin: 0;
      color: #6d28d9;
      cursor: pointer;
    }
  }
  .empty-table-message {
    font-size: 18px;
    font-weight: 600;
    padding: 25px;
  }
}

.nowrap {
  white-space: nowrap;
}

.table-preloader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 180px);
}
