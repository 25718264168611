.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 24px;
  border-bottom: 1px solid #f4f4f4;
  max-height: 90px;
  @media (max-width: 540px) {
    max-height: initial;
  }
  @media (max-width: 375px) {
    border-bottom: none;
  }
  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: #0f2a3c;
  }
  h4 {
    margin: 0;
  }
  .header-responsive-title {
    flex-basis: 100%;
    padding: 16px 20px 16px 0;
  }
}
.header-title {
  display: flex;
  gap: 20px;
  align-items: center;
  h2 {
    min-width: max-content;
  }
}

.toggle-responsive-menu {
  cursor: pointer;
}
.header-main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  border-right: 1px solid #f4f4f4;
  @media (max-width: 840px) {
    border-right: none;
    padding-right: 0;
  }
}
.header-notification {
  cursor: pointer;
}
.header-user {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 24px;

  @media (max-width: 840px) {
    padding-left: 0;
  }

  img {
    max-height: 50px;
    border-radius: 50%;

    @media screen and (max-width: 375px) {
      border-radius: 0%;
    }
  }
  .user-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: ease all 0.3s;

    @media (min-width: 541px) {
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-bottom: 1px solid #6a6565;
        border-right: 1px solid #6a6565;
        transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    @media (max-width: 540px) {
      position: relative;
      .responsive-dot {
        position: absolute;
        width: 10px;
        height: 10px;
        right: 16px;
        bottom: 16px;
        background-color: #007a5a;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .default-image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      color: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      font-size: 30px;
      @media (max-width: 540px) {
        width: 40px;
        height: 40px;
      }
    }
    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
    }
  }
}

.user-wrapper {
  position: relative;
  .profile-options {
    list-style: none;
    padding: 0;
    position: absolute;
    top: 90px;
    min-width: 260px;
    right: 0;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    z-index: 5;
    li {
      padding: 8px 12px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      a {
        display: block;
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.user-actions-_offcanvas {
  top: 0 !important;
  min-width: auto;
  min-height: 100vh;
  width: 100vw;
}

.user-actions-_offcanvas._offcanvas {
  background-color: #0f2a3c;
  border: none !important;
  color: #fff;
  .user-actions-header {
    padding: 24px 24px 0;
  }
  .user-actions-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    h4 {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 800;
    }
    ul {
      list-style: none;
      flex-grow: 1;
      padding: 0;
      li {
        display: flex;
        gap: 20px;
        padding: 15px 0;
        align-items: center;
        cursor: pointer;
        svg {
          width: 24px;
        }
        span {
          font-size: 16px;
          color: #fff;
        }
        a {
          display: flex;
          gap: 20px;
          font-size: 16px;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    p {
      margin: 1rem 0;
      cursor: pointer;
    }
  }
}

.user-wrapper .profile-options li:hover::after {
  content: "";
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #6a6565;
  border-right: 1px solid #6a6565;
  transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.header-user-text {
  display: block;
  p {
    display: flex;
    align-items: center;
    gap: 8px;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: #007a5a;
      border-radius: 50%;
    }
  }
}

.header-user-text p.yellow-dot::before,
.responsive-dot.yellow-dot {
  background-color: #febe00;
}

.header-user-text p.gray-dot::before,
.responsive-dot.gray-dot {
  background-color: #999;
}

.header-user-text p.red-dot::before,
.responsive-dot.red-dot {
  background-color: #d10000;
}

.header-main .outside-click {
  position: relative;
}
.notification-icon {
  border-radius: 50%;
  padding: 5px;
}
.notification-icon:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.header-notification-list {
  position: absolute;
  right: -25px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
}
.header-notification-list a {
  text-decoration: none;
  color: inherit;
}
.notification-list-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  min-width: 400px;
  font-size: 14px;
  box-shadow: 0px 1px 0px rgba(225, 225, 225, 0.65);
  transition: ease all 0.3s;
}
.notification-list-item p {
  margin: 0;
  flex-grow: 1;
}

.notification-icon-default {
  background-color: #f2f2f2;
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.notification-list-item:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #ffffff;
}
.notification-list-item img {
  border-radius: 50%;
  width: 32px;
}

.notification-list-item span {
  color: #6a6565;
}

.modal-body .select__indicator-separator,
.form-group .select__indicator-separator {
  display: none;
}
.select__input-container .select__input:focus {
  border: none !important;
}
.basic-multi-select:focus-visible,
.modal-body .select__control--is-focused {
  outline: none;
  border: 1px solid #0f2a3c;
  border-color: #0f2a3c;
  box-shadow: none;
}

.side-profil-wrapper {
  position: fixed;
  top: 90px;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 4;
  border-left: 1px solid #f4f4f4;
}

.side-profil-wrapper .user-info-wrapper {
  max-height: initial;
  border: none;
}

.header-user .user-image img {
  max-height: initial;
}

.button-input {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px 8px 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-input .outside-click {
  width: fit-content;
}
.button-input.tab-content button {
  padding: 8px 20px;
}

.emoji-form-group .outside-click,
.emoji-form-group .outside-click div {
  width: fit-content;
}

@media (max-width: 375px) {
  .availability-modal {
    .modal-dialog {
      margin: 0;
      justify-content: flex-start;
      align-items: stretch;
      .modal-content {
        background-color: #0f2a3c;
        color: #fff;
        form {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          .modal-header {
            position: relative;
            border-bottom: none;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 15px;
              right: 15px;
              height: 1px;
              background-color: #f4f4f4;
              opacity: 0.1;
            }
            .modal-title {
              display: flex;
              gap: 10px;
            }
          }
          .modal-body {
            max-height: initial;
            h3 {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 16px;
            }
            .form-group {
              margin-bottom: 8px;
              textarea {
                background-color: #0f2a3c;
                border-radius: 6px;
                min-height: 56px;
                color: #fff;
                &:focus-visible {
                  border-color: #fff;
                }
              }
              .button-input {
                background-color: #0f2a3c;
                border-radius: 6px;
                min-height: 56px;
                color: #fff;
                button {
                  background-color: #fff;
                  color: #0f2a3c;
                }
              }
            }
            .form-label {
              color: #fff;
            }
            .select__control {
              background-color: #0f2a3c;
              border-radius: 6px;
              min-height: 56px;
              .select__single-value {
                color: #fff;
              }
              .select__placeholder {
                color: #fff;
              }
            }
          }
          .modal-footer {
            button {
              background-color: #fff;
              opacity: 1;
              color: #0f2a3c;
              width: 100%;
              margin: 0 10px 10px;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
