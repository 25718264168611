.position-form-header {
  border-bottom: 1px solid #f4f4f4;
  .header-title {
    display: block;
    span {
      display: block;
      font-size: 14px;
      color: #a1a1a1;
      margin-bottom: 6px;
      cursor: pointer;
    }
    h2 {
      display: block;
    }
  }
  .btn-primary {
    min-width: 179px;
    span {
      text-align: center;
      width: 100%;
    }
  }
}
.career-position-form {
  margin: 30px 24px;
  max-width: 70%;
  max-height: calc(100vh - 248px);
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  overflow-y: scroll;
  h3 {
    font-size: 18px;
    font-weight: 600;
    padding: 16px 20px;
    border-bottom: 1px solid #f4f4f4;
  }
  .career-form-group {
    display: flex;
    align-items: flex-start;
    padding: 12px 20px;
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
    .file-wrapper {
      width: 100%;
      .file-select {
        label {
          color: #4570f3;
        }
      }
    }
    .career-add-wrapper {
      width: 100%;
      ul {
        list-style: none;
        padding: 10px 0 0;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
            color: #4570f3;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f5f5f5;
            padding: 7px;
            border: none;
            border-radius: 6px;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .select-wrapper {
      width: 100%;
      max-width: 460px;
    }
    .form-control {
      margin: 0;
      border: 1px solid #e1e1e1;
      color: #666;
      font-size: 14px;
    }
    label {
      min-width: 200px;
      color: #666666;
      padding: 12px 0;
    }
    input {
      min-height: 56px;
      font-size: 14px;
      max-width: 460px;
      min-width: 200px;
      margin: 0;
      border: 1px solid #e1e1e1;
      color: #666;
    }
    .career-add {
      padding: 12px 0 0;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      span {
        color: #4570f3;
        font-size: 14px;
      }
      path {
        fill: #4570f3;
      }
    }
  }
  .mentor-select {
    .dropdown {
      max-height: max-content;
    }
  }
}
.position-employees-modal {
  .modal-dialog {
    min-width: 50%;
    .career-content {
      padding: 0;
    }
  }
}

.input-holder {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .form-group {
    flex-grow: 1;
    .dropdown-selected {
      max-height: 48px;
    }
  }
}
.selected-files {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  button {
    border: none;
    background-color: transparent;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 6px;
    font-size: 14px;
    margin-bottom: 12px;
    a {
      color: initial;
      text-decoration: none;
      cursor: pointer;
    }
    svg {
      width: 10px;
      path {
        fill: #737373;
      }
    }
  }
}
.file-select {
  position: relative;
  margin-bottom: 25px;
  width: 100%;
  .file-select-empty {
    background-color: #f5f5f5;
    color: #6a6a6a;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    margin: 8px 0 13px 0;
    padding: 14px 20px;
    font-size: 14px;
  }
  label {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    color: #4570f3;
    font-size: 16px;
    path {
      fill: #4570f3;
    }
  }
  input {
    background-color: transparent;
    border: none;
    max-height: initial;
  }
  input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
  }

  input[type="file"]::before {
    content: "";
  }

  input[type="file"]:not(:empty)::before {
    content: "File selected";
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
    cursor: pointer;
  }
}
.multi-actions {
  button {
    margin-left: 10px;
  }
}

.date-picker-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .form-group {
    position: relative;
    margin-bottom: 0;
    svg {
      position: absolute;
      right: 10px;
      top: 42px;
    }
    input {
      width: 100%;
      min-width: initial;
      margin-top: 0;
    }
  }
}
