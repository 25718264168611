.career-content {
  height: 100%;
  position: relative;
  .matrix-actions {
    button {
      min-width: 175px;
      justify-content: center;
    }
  }
  .lines-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
.matrix-container {
  position: relative;
  max-width: calc(100vw - 300px);
  overflow: scroll;
  z-index: 1;
  .cover-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    opacity: 0.5;
    z-index: 2;
  }
  .matrix-wrapper {
    position: relative;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    box-shadow: inset 0 0 0 1px #f4f4f4;
    min-width: 1395px;

    .matrix-item {
      position: relative;
      box-shadow: inset 0 0 0 1px #f4f4f4;
      width: 115px;
      height: 64px;

      &::before {
        content: "";
        position: absolute;
        right: -30px;
        bottom: -30px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 0 1px #f4f4f4;
        pointer-events: none;
      }

      .block-content,
      .selected-block {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ecf1ff;
        color: #4570f3;
        border-radius: 12px;
        font-size: 13px;
        cursor: pointer;
        visibility: hidden;
      }
      .selected-block {
        background-color: #f4f4f4;
        visibility: visible;
        padding: 14px 18px;
        h4 {
          font-size: 13px;
          font-weight: 600;
          color: #0f2a3c;
          margin: 0;
        }
      }
      .block-content.visible {
        visibility: visible;
      }
      .block-selector {
        position: absolute;
        padding: 24px;
        background-color: #fff;
        border: 1px solid #f4f4f4;
        border-radius: 8px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        z-index: 3;

        .career-form-footer {
          display: flex;
          gap: 10px;
          width: 100%;
          justify-content: flex-end;
          .btn {
            margin: 20px 0 0;
            background-color: #0f2a3c;
          }
        }
        h2 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 24px;
        }
        .dropdown {
          margin-bottom: 12px;
          .dropdown-selected {
            min-width: 350px;
          }
        }
      }
      .block-selector.top {
        bottom: 65px;
      }
      .block-selector.left {
        right: 0;
      }
    }
    .svg-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.career-form-wrapper {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #00000026;
  border-radius: 10px;
  padding: 20px;
}
