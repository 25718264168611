a {
  cursor: pointer;
}

.trend {
  display: flex;
  width: fit-content;
  padding: 5px;
  align-items: center;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: #0000000a;
  }
}
.trend-up {
  color: #008000ff;
  cursor: default;
}
.trend-down {
  color: #8b0000ff;
  cursor: default;
}
.chartHeight {
  flex: 1 0;
  position: relative;
  height: 50vh;
  min-width: 30%;
  padding: 30px;
}

.chartHeightTeams {
  float: left;
  position: relative;
  height: 30vh;
  width: 20vw;
  padding-bottom: 50px;
  margin: 50px;
}
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.reports-wrapper {
  display: flex;
  padding: 0;
  max-height: calc(100vh - 90px);
  overflow: auto;

  .module-content {
    flex-grow: 1;
    .reports-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 24px 20px 30px;
      border-bottom: 1px solid #f4f4f4;

      h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .header-actions {
        display: flex;
        gap: 30px;
        align-items: center;
      }
      @media (max-width: 595px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;

        .header-actions {
          flex-direction: column;
          align-items: flex-start;
          width: 75%;
          .table-search {
            width: 100%;
          }
          gap: 15px;
        }
      }

      @media (max-width: 400px) {
        .header-actions {
          width: 100%;
          .custom-button {
            justify-content: center;
          }
          > * {
            width: 100%;
          }
        }
      }
    }
  }
  .input-group {
    padding: 20px 30px;
    justify-content: space-between;
    grid-gap: 30px;
    gap: 30px;
    border-bottom: 1px solid #f4f4f4;
    h4 {
      flex-grow: 1;
      margin: 0;
    }
    .form-control {
      margin: 0;
    }
  }
  .nav-tabs {
    min-width: 300px;
    @media only screen and (max-width: 1300px) {
      min-width: 200px;
    }
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    border-bottom: none;
    border-right: 1px solid #f4f4f4;
    .nav-link {
      border: none;
      color: #000;
      width: 100%;
      text-align: left;
      padding: 13px 0 13px 60px;
      &:hover {
        background-color: #f6f6f6;
      }
    }
    .nav-link.active {
      background-color: #f6f6f6;
    }
  }
  .tab-content {
    flex-grow: 1;
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      margin: 0;
      color: #000;
    }
    .reports-page {
      display: flex;
      .reports-table {
        padding: 30px;
        border-right: 1px solid #f4f4f4;
        flex-grow: 1;
        max-width: calc(100vw - 600px);
        overflow: scroll;
        tr {
          th {
            min-width: 110px;
          }
          th.large-column {
            min-width: 140px;
          }
          td {
            justify-content: flex-start;
          }
          td.icon-row-item {
            display: flex;
            div {
              display: flex;
              align-items: center;
              padding: 10px;
              border-radius: 50%;
              &:hover {
                background-color: rgba(0, 0, 0, 0.04);
                cursor: pointer;
              }
            }
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 20px;
          margin: 20px;
        }
      }
    }
  }
}
.user-data {
  display: flex;
  align-items: center;
  gap: 14px;
  color: #000;
}
.default-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: #fff;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
}
.bolder {
  font-weight: bold;
}
