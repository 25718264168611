.status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 100px;
  padding: 7px 10px;
  font-size: 12px;
}
.status.blue {
  background-color: #6ac3d7;
}
.status.green {
  background-color: #a9ce5b;
}
.status.purple {
  background-color: #96a3e4;
}
.status.red {
  background-color: #f07171;
}
