.toaster-wrapper {
  position: fixed;
  z-index: 100;
  bottom: 1rem;
  right: 1rem;

  .toaster {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-radius: 6px;
    box-shadow: 0 0 10px #999;
    color: #000;
    background-color: none;
    padding: 8px;
    gap: 8px;
    animation: toast-in 0.7s ease forwards;
    .icon {
      display: flex;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .toaster-message {
      display: block;
      font-size: 14px;
      padding: 8px;
      .title {
        font-weight: 600;
      }
    }
    svg {
      path {
        fill: black;
      }
    }
  }
}

.toaster[type="success"] {
  background-color: rgb(150, 200, 150);
  .icon {
    svg {
      path {
        fill: green;
      }
    }
  }
}
.toaster[type="error"] {
  background-color: rgb(252, 200, 200);
  .icon {
    svg {
      path {
        fill: red;
      }
    }
  }
}
.toaster[type="warning"] {
  background-color: rgb(235, 235, 75);
  .icon {
    svg {
      path {
        fill: orange;
      }
    }
  }
}
.toaster[type="info"] {
  background-color: rgb(150, 150, 240);
  .icon {
    svg {
      path {
        fill: blue;
      }
    }
  }
}

.closing {
  animation: toast-out 0.7 ease forwards;
}

@keyframes toast-in {
  0% {
    transform: translateX(400px);
    opacity: 0;
  }
  50% {
    transform: translateX(-60px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes toast-out {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(-60px);
    opacity: 1;
  }
  100% {
    transform: translateX(400px);
    opacity: 1;
  }
}
