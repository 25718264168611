.chat-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px;
  h5 {
    font-size: 12px;
    color: #a1a1a1;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    &::before {
      border-color: #a1a1a1;
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: "";
      display: block;
      height: 6px;
      width: 6px;
      margin-bottom: 3px;
      transform: rotate(135deg);
    }
  }
}

.chat-control.closed h5::before {
  transform: rotate(-45deg);
  margin-bottom: 0;
}

.chat-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 8px 32px;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background-color: rgba(161, 161, 161, 0.1);
  }
  p {
    margin: 0;
    color: #6a6565;
    span {
      color: #6a6565;
      margin-right: 12px;
    }
  }
  p.active {
    color: #000;
  }
  .header-user {
    padding: 0;
    border: none;
  }
  .header-user img,
  .default-image {
    height: 32px;
    width: 32px;
    font-size: 18px;
    position: relative;
  }
}

.chat-item.active {
  background-color: rgba(161, 161, 161, 0.1);
}

.chat-item .header-user .chat-user-img {
  position: relative;
}

.chat-item .chat-user-img img {
  max-width: initial;
}

.chat-item .header-user .chat-user-img::after,
.chat-item .default-image::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #007a5a;
  border: 2px solid #fff;
}
.chat-item .header-user .chat-user-img.yellow::after,
.chat-item .default-image.yellow::after {
  background-color: #febe00;
}

.chat-item .header-user .chat-user-img.red::after,
.chat-item .default-image.red::after {
  background-color: #d10000;
}
