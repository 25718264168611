.progress-wrapper {
  display: flex;
  gap: 10px;
  max-height: 18px;
  .progress-bar {
    min-width: 95px;
    border: 1px solid #ddd;
    border-radius: 25px;
    overflow: hidden;
    .progress-scale {
      display: flex;
      height: 100%;
      padding: 0;
      transition: all ease 0.2s;
    }
    .progress-scale.red {
      background-color: #c50813;
    }
    .progress-scale.orange {
      background-color: #fea737;
    }
    .progress-scale.green {
      background-color: #58d498;
    }
  }
  p {
    margin: 0;
  }
}
