.popup-wrapper {
  position: relative;
  .popup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border: none;
    border-radius: 6px;
    background-color: #f5f5f5;
    color: #666;
    font-size: 20px;
  }
  .popup-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    padding: 15px;
    margin: 0;
    bottom: 0;
    right: calc(100% + 5px);
    color: #666;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    z-index: 9;
    li {
      cursor: pointer;
    }
  }
}
