.personal-performance-wrapper {
  padding-left: 11px;
  .personal-performance-content {
    max-height: calc(100vh - 90px);
    overflow-y: scroll;
    padding: 13px;
    .personal-performance-header {
      display: flex;
      justify-content: space-between;
      .user-card-wrapper {
        display: inline-flex;
        padding: 7px;
        border: 2px solid #d5e7f2;
        border-radius: 5px;
        font-size: 12px;
        .secondary-text {
          font-size: 8px;
          color: #a1a1a1;
        }
        .user-left {
          border-right: 1px solid #f4f4f4;
          padding-right: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .user-bottom {
            color: #a1a1a1;
          }
        }
        .user-right {
          padding-left: 15px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          div {
            flex-grow: 1;
          }
          .user-bordered {
            border-bottom: 1px solid #f4f4f4;
          }
        }
      }
      .header-info {
        display: flex;
        gap: 20px;
        position: relative;
        .about-me-wrapper {
          position: relative;
          textarea {
            width: 355px;
            height: 100%;
            resize: none;
            padding: 20px;
            padding-right: 70px;
            background-color: #f5f5f5;
            border: 1px solid #e1e1e1;
            border-radius: 6px;
            font-size: 14px;
            overflow: scroll;
          }
          .action-wrapper {
            position: absolute;
            right: 10px;
            bottom: 10px;
            display: flex;
            gap: 5px;
            svg {
              path {
                fill: #666;
              }
            }
            .action.confirm {
              svg {
                path {
                  fill: #a9ce5b;
                }
              }
            }
            .action.close {
              svg {
                path {
                  fill: #f07171;
                }
              }
            }
          }
        }
        .button-group {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .custom-button {
            justify-content: center;
          }
          .secondary {
            border: 1px solid #e1e1e1;
            min-width: max-content;
            text-align: center;
          }
        }
      }
    }
    .personal-performance-user-info {
      table {
        border: 1px solid #f4f4f4;
        margin-top: 16px;
        min-width: 100%;
        border-collapse: collapse;
        thead {
          border-bottom: 1px solid #f4f4f4;
          color: #666666;
          font-size: 14px;
          tr {
            td {
              border-right: 1px solid #f4f4f4;
              border-bottom: 1px solid #f4f4f4;
            }
          }
        }
        tbody {
          color: #0f2a3c;
          font-size: 14px;
        }
        td {
          padding: 20px;
        }
      }
    }
    .personal-performance-views {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      .performance-view {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        h3 {
          flex-grow: 1;
          color: #0f2a3c;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          padding: 5px 10px;
          border-radius: 6px;
          background-color: rgba(38, 62, 78, 0.1);
        }
        .view-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #355469;
          width: 34px;
          height: 34px;
          border-radius: 50%;
        }
      }
      .expandable-content {
        .single-view-wrapper {
          transition: all ease 0.2s;
          overflow: hidden;
          overflow-y: scroll;
          .single-view-section {
            h2 {
              font-size: 14px;
              font-weight: 600;
            }
            h3 {
              font-size: 14px;
              font-weight: 600;
            }
            .single-view-content-flex {
              display: flex;
              gap: 20px;
              .single-view-half {
                width: 50%;
                .pie-wrapper {
                  width: 200px;
                  height: 200px;
                }
              }
            }
            .main-list {
              display: flex;
              flex-direction: column;
              gap: 13px;
              padding: 0;
              max-height: 290px;
              overflow-y: scroll;
              li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                padding: 14px 18px;
                border: 1px solid #f4f4f4;
                border-radius: 10px;
                .main-list-item {
                  flex-basis: calc(100% / 3);
                }
              }
              li.main-list-labels {
                border: none;
                font-size: 12px;
                padding-bottom: 0;
                span {
                  flex-basis: calc(100% / 3);
                  color: #666;
                }
              }
            }
          }
        }
      }
    }
  }
  .limited-height-typeahead-dropdown {
    .modal-dialog .modal-content .modal-body {
      padding-bottom: 120px;
    }
  }
  .preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  td.preloader-wrapper {
    display: table-cell;
    text-align: center;
    padding: 5px !important;
  }
}

.limited-height-typeahead-dropdown .typeahead-search {
  max-height: 118px;
}
