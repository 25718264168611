.new-survey-wrapper {
  height: 75vh;
  overflow: auto;

  .survey-options {
    display: flex;
    padding: 30px 50px;
    gap: 30px;
    justify-content: center;

    .survey-details {
      width: 100%;
      max-width: 670px;

      .switcher-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border: 1px solid #e1e1e1;
        border-radius: 6px;
        height: 56px;
        width: 100%;
        background-color: #f5f5f5;
        padding: 15px;
        font-size: 14px;
        color: #6a6565;
      }

      .inline-group {
        display: flex;
        gap: 8px;
        margin-bottom: 24px;
      }

      .inline-group.last {
        margin-top: 24px;
        align-items: end;
      }

      .block-group {
        width: 100%;

        label {
          margin-bottom: 8px;
          color: #6a6565;
        }
      }

      .link-holder {
        border: 1px solid #e1e1e1;
        color: #e1e1e1;
        border-radius: 6px;
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 20px;
      }

      svg {
        rotate: 270deg;
      }
    }

    .survey-dates {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .datepicker-wrapper {
        display: block;
        justify-content: flex-end;

        .date-picker-holder {
          .react-datepicker {
            border: none;
          }
        }

        label {
          display: block;
          margin-bottom: 8px;
          color: #6a6565;
        }
      }
    }
  }
}
.question-creator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 40px 20px 40px;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .question-creator-actions {
    display: flex;
  }
}
.question-creator {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  max-width: 1250px;
  margin: 40px;
  padding: 20px;
  border: 1px solid #e1e1e1;
  max-height: calc(100vh - 268px);
  overflow-y: scroll;
  path {
    stroke: #5f6368;
  }
  .add-question {
    width: 100%;
    max-width: 850px;
    text-align: right;
    .add-icon {
      display: inline-flex;
      padding: 14px;
      background-color: #f5f5f5;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
.question-wrapper {
  width: 100%;
  border: 2px solid #e1e1e1;
  max-width: 850px;
  border-radius: 6px;
  padding: 15px;
  .question-header {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    .question-inputs {
      width: 70%;
    }
  }
  .question-answer {
    padding: 0 0 15px;

    .answer-list {
      padding: 0;
      width: 70%;
      li {
        display: flex;
        align-items: center;
        // gap: 20px;
        input {
          margin: 0;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #f4f4f4;
        }
        input[type="radio"] {
          border: 2px solid transparent;
        }
        input[type="radio"]:checked {
          border: 2px solid #fff;
          background-color: #5f6368;
        }
        input[type="checkbox"] {
          border: 2px solid transparent;
        }
        .remove-answer {
          svg {
            width: 11px;
            path {
              fill: #000;
            }
          }
        }
      }
      .add-choice {
        text-align: right;
        margin-top: 5px;
        cursor: pointer;
      }
    }
    .radio-list,
    .checkbox-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  .question-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #f5f5f5;
    padding-top: 15px;
    span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      padding: 7px;
      border-radius: 6px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-left: 10px;
      &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e1e1e1;
      }
    }
  }
}
