.header-title {
  display: block;
  span {
    display: block;
    font-size: 14px;
    color: #a1a1a1;
    margin-bottom: 6px;
  }
  h2 {
    display: block;
  }
}
.career-position-content {
  display: flex;
  gap: 24px;
  padding: 30px 24px;
  min-height: calc(100vh - 178px);
  overflow-y: scroll;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
  .position-details {
    flex-basis: 70%;
    border: 1px solid #f4f4f4;
    border-radius: 12px;
    .details-header {
      padding: 16px 20px;
      border-bottom: 1px solid #f4f4f4;
    }
    .details-block {
      display: flex;
      align-items: flex-start;
      padding: 24px 20px;
      border-bottom: 1px solid #f4f4f4;
      &:last-child {
        border: none;
      }
      label {
        flex-basis: 30%;
        min-width: 30%;
        font-size: 12px;
        color: #666666;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        flex-grow: 1;
        li {
          font-size: 14px;
        }
        li.clickable-list-item {
          color: #4570f3;
          cursor: pointer;
        }
        li.status {
          display: flex;
          justify-content: space-between;
          p {
            color: #0f2a3c;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .details-resources {
        li {
          background-color: #f5f5f5;
          padding: 7px 9px;
          margin-bottom: 12px;
          min-width: 300px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            color: initial;
            text-decoration: none;
          }
        }
      }
    }
  }
  .position-note {
    flex-basis: 30%;

    .position-note-wrapper {
      border: 1px solid #f4f4f4;
      border-radius: 12px;
      display: flex;
      gap: 12px;
      padding: 24px;
      h3 {
        margin-bottom: 12px;
      }
      .icon-wrapper {
        background-color: #f5f5f5;
        border-radius: 50%;
        padding: 8px;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
