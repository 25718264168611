.projects-wrapper {
  .projects-header {
    display: flex;
    padding: 35px 30px;
    border-bottom: 1px solid #f4f4f4;
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
  }
  .projects-content {
    padding: 40px 30px;
    .projects-content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
      p {
        font-size: 14px;
        color: #8c8c8c;
      }
    }
    .projects-status-filters {
      display: flex;
      gap: 12px;
      margin: 25px 0;
      .projects-filter {
        flex-basis: 20%;
        border: 2px solid #f4f4f4;
        border-radius: 10px;
        padding: 20px 24px;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          background-color: #f4f4f4;
        }
        p {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 600;
        }
        span {
          font-size: 36px;
          font-weight: 600;
          color: #000;
        }
      }
      .projects-filter.active {
        background-color: #f4f4f4;
      }
    }
    .project-table-filters {
      display: flex;
      align-items: center;
      gap: 24px;
      .table-search {
        flex-basis: 60%;
        max-height: 48px;
        border: 1px solid #ddd;
        border-radius: 6px;
        svg {
          top: 15px;
        }
        input {
          max-height: 46px;
          border: none;
        }
      }
      .date-filter {
        flex-basis: 25%;
        border: 1px solid #e1e1e1;
        border-radius: 6px;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          font-size: 14px;
          &::after {
            content: "";
            display: inline-block;
            margin-left: 10px;
            width: 10px;
            height: 10px;
            border-right: 2px solid #e1e1e1;
            border-bottom: 2px solid #e1e1e1;
            transform: rotate(45deg);
          }
        }
      }
      .sort-filter {
        flex-basis: 15%;
        p {
          margin: 0;
          font-size: 14px;
          cursor: pointer;
          span {
            color: #6d28d9;
          }
          &::after {
            content: "";
            display: inline-block;
            margin-left: 10px;
            margin-bottom: 3px;
            width: 8px;
            height: 8px;
            border-right: 1px solid #6d28d9;
            border-bottom: 1px solid #6d28d9;
            transform: rotate(45deg);
          }
        }
      }
    }
    .table-wrapper {
      padding: 0;
      margin-top: 25px;
      thead tr th {
        color: #666;
        font-weight: 400;
      }
      table td {
        padding: 18px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        span {
          cursor: initial;
        }
        span.blue {
          padding: 4px 16px;
          background-color: #cfd9fb;
          color: #1040e9;
        }
      }
      table td.table-actions {
        overflow: visible;
      }
    }
  }
}
.tasks-modal {
  .modal-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
    }
    .progress-wrapper {
      min-width: 135px;
      p {
        min-width: max-content;
      }
    }
  }
  .modal-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #f1f1f1;
    .modal-date-element {
      display: flex;
      align-items: center;
      gap: 15px;
      p {
        margin: 0;
        font-size: 14px;
        color: #6a6565;
      }
    }
    .modal-date-element.date-picker-holder {
      svg {
        position: unset;
      }
      .react-datepicker-wrapper {
        max-width: 80%;
        .react-datepicker__input-container {
          input {
            margin-bottom: 8px;
            background-color: transparent;
            border: none;
            padding: 0;
          }
        }
      }
    }
    img {
      max-width: 28px;
    }
  }
  .modal-task-options {
    padding: 20px 0;
    border-bottom: 1px solid #f1f1f1;
    .option-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 13px;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        max-width: 27%;
      }
      ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        border: 1px solid #f4f4f4;
        border-radius: 12px;
        overflow: hidden;
        li {
          font-size: 14px;
          padding: 18px;
          border-right: 1px solid #f4f4f4;
          transition: all ease 0.2s;
          cursor: pointer;
          &:last-child {
            border-right: none;
          }
          &:hover {
            background-color: #f4f4f4;
          }
        }
        li.active {
          background-color: #f4f4f4;
        }
      }
      .custom-input {
        max-width: 150px;
        border: 2px solid #f4f4f4;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px 15px;
        margin-bottom: 0;
        label {
          text-transform: uppercase;
          font-size: 10px;
        }
        input {
          margin: 0;
          padding: 3px 0 0;
          background-color: transparent;
          border: none;
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
      .disabled-input {
        background-color: #f4f4f4;
      }
      .input-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
  .modal-comments {
    overflow-y: scroll;
    padding: 20px;
    max-height: 7.5rem;
    .single-comment{
      margin-bottom: 15px;
      .comment-header{
        margin-bottom: 10px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        .user{
          display: flex;
          gap: 8px;
          align-items: center;
          p{
            font-size: 14px;
            font-weight: 700;
          }
        }
        p{
          color: #6a6a6a;
          font-size: 10px;
        }
      }
      .comment-body{
        padding: 15px;
        border-radius: 6px;
        border: 1px solid #e3e3e3;
        font-size: 12px;
      }
    }
  }

  .modal-textbox {
    padding: 20px 0 0;
    .input-wrapper{
      textarea{
        margin-bottom: 0;
      }
    }
  }
}
