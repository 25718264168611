.career-wrapper {
  margin: 20px;
  border: 1px solid #f5f5f5;
  .career-header {
    border-bottom: 1px solid #f5f5f5;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    .current-employee {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-grow: 1;
      color: #666;
      .user-icon {
        display: inline-flex;
      }
    }
    h2 {
      font-size: 18px;
      margin: 0;
    }
    .career-view-switcher {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      color: #666;
    }
  }
  .react-flow__node {
    visibility: visible !important;
    .text-updater-node {
      .react-flow__handle {
        background-color: rgb(234, 232, 232);
        border-color: rgb(234, 232, 232);
      }
    }
    .custom-block {
      border: 2px solid #e1eef6;
      background-color: #fff;
      border-radius: 5px;
      width: 170px;
      display: flex;
      flex-direction: column;
      min-height: 150px;
      .custom-block-top {
        display: flex;
        justify-content: space-between;
        h3 {
          max-width: 50%;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .custom-block-position {
        font-size: 12px;
        color: #a1a1a1;
        margin: 10px 0;
      }
      .custom-block-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .custom-block-expand {
          cursor: pointer;
          &::after {
            content: "";
            margin-left: 7px;
            margin-bottom: 3px;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-bottom: 1px solid #a1a1a1;
            border-left: 1px solid #a1a1a1;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.administration-modal {
  .dropdown {
    margin-bottom: 20px;
  }
}
