._offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  height: 100%;
  background-color: var(--slack-color);
}

.start {
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }
}

.end {
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }
}

.render-right {
  animation: slideInFromRight 0.3s ease-in-out;
}
.render-left {
  animation: slideInFromLeft 0.3s ease-in-out;
}
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

._offcanvas-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

._offcanvas-backdrop {
  position: fixed;
  z-index: 1039;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}
