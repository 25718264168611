.first-row {
  width: 20%;
  min-width: 200px;
  @media (max-width: 420px) {
    min-width: inherit;
  }
}
@media (max-width: 768px) {
  .pagination {
    justify-content: center;
  }
}
.current-user {
  color: #6a6565;
  pointer-events: none;
}

.user-info-offcanvas {
  top: 190px !important;
  min-width: initial;
  width: 430px !important;
  height: auto;
  background-color: #fff !important;
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
  .user-image {
    margin-bottom: 20px;
  }
}

.support-user-header,
.support-user-info,
.support-user-projects {
  padding: 30px;
  ul {
    list-style: none;
    padding: 0;
    li {
      color: #6a6a6a;
      font-size: 14px;
    }
  }
}

.support-user-header,
.support-user-info {
  border-bottom: 1px solid #f4f4f4;
}

.support-user-header {
  img {
    display: block;
    margin: 0 auto 20px;
    max-width: 282px;
    border-radius: 6px;
  }
  h3 {
    font-size: 24px;
    color: #0f2a3c;
    font-weight: 600;
  }
  .support-header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .support-header-availability {
      p {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        color: #6a6565;
        margin: 0;
      }
      p::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: #007a5a;
        border-radius: 50%;
      }
    }
    button {
      font-size: 14px;
      border: 1px solid #0f2a3c;
      border-radius: 6px;
      background-color: #fff;
      padding: 16px;
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.support-user-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h4 {
    font-size: 16px;
    margin: 0;
  }
  p {
    font-size: 14px;
    color: #6a6a6a;
    margin: 0;
  }
}
