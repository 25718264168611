// Global Styles
*,
:after,
:before {
  box-sizing: border-box;
}
.App p {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.bold {
  font-weight: 800;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.grid {
  display: grid;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.gap-5 {
  gap: 5px;
}
.gap-8 {
  gap: 8px;
}
.gap-4 {
  gap: 4px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}
.gap-24 {
  gap: 24px;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}

.padding-0 {
  padding: 0;
}

.vertical-line {
  width: 1px;
  background-color: #e1e1e1;
}

.header-actions {
  button {
    max-height: 48px;
  }
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.py-3 {
  padding-top: 0.75rem; /* 10px */
  padding-bottom: 0.75rem;
}
.px-4 {
  padding-left: 1rem; /* 16px */
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem; /* 16px */
  padding-bottom: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.px-6 {
  padding-left: 1.5rem; /* 24px */
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem; /* 24px */
  padding-bottom: 1.5rem;
}
hr {
  border-top: 1px solid #d9d9d9;
  border-bottom: 0;
  border-left: 0;
  margin: 20px 0;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}
