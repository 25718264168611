.sidebar {
  background-color: var(--slack-color);
  color: white;
  overflow-y: auto;
  overflow-x: scroll;
  position: relative;
  padding: 30px 24px;
  box-sizing: border-box;
  min-width: 300px;
  transition: all ease 0.2s;
  .sidebar-content {
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
  }
  @media (max-width: 375px) {
    min-width: 100vw;
  }
}

.sidebar.closed {
  min-width: 108px;
  transition: all ease 0.2s;
}

.sidebar.closed .sidebarOption {
  gap: 0;
}

.sidebar h5 {
  width: auto;
  opacity: 1;
  visibility: visible;
  transition: all ease 0.2s;
}

.sidebar.closed h5 {
  width: 0px;
  opacity: 0;
  visibility: hidden;
}

.sidebar-icon {
  margin-left: 1em;
}
#example-collapse-text-single > div {
  display: inline-flex;
  min-width: 5em;
}
.chat-header {
  background: none !important;
  border: none !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.chat-header:focus {
  box-shadow: none !important;
}
.add-chat {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 2px;
  font-size: larger;
  border-top: 1px solid white !important;
  width: 100%;
  border-bottom: 1px solid white !important;
  border-radius: 0px !important;
}

.add-chat:hover {
  background-color: #2f2f49 !important;
}

::-webkit-scrollbar {
  display: none;
}

.sidebar-header > .MuiSvgIcon-root {
  padding: 8px;
  color: #1d1d42;
  font-size: 18px;
  background-color: white;
  border-radius: 999px;
  margin-left: 10px;
}

.sidebar-info {
  flex: 1;
}

.sidebar-info > h2 {
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 5px;
}

.sidebar-info > h3 {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}

.sidebar-info > h3 > .MuiSvgIcon-root {
  color: green;
  font-size: 14px;
  margin-top: 1px;
  margin-right: 2px;
}

.sidebar > hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #1d1d42;
}

@media only screen and (max-width: 550px) {
  .sidebar-header > .MuiSvgIcon-root {
    margin-left: 10px;
  }
}

.logged-in {
  color: green;
  margin-left: 1em;
  margin-right: 1em;
}

.logged-out {
  color: red;
  margin-left: 1em;
  margin-right: 1em;
}
.unread {
  color: #1d1d42 !important;
  background-color: #f2f2f2 !important;
  width: 100%;
  padding: 5px !important;
}
.unread > .sidebarOption:hover {
  background-color: #898989 !important;
  color: #1d1d42 !important;
}

.logo-wrapper {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(244, 244, 244, 0.1);
}

.logo-wrapper .modal-header-close {
  margin: 0 0 0 auto;
}

.sidebar-toggler {
  flex-grow: 1;
  text-align: right;
}

.logo-wrapper span svg {
  transition: all ease 0.2s;
}

.logo-wrapper span.closed svg {
  transform: rotate(180deg);
}

.subnav {
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
  li {
    a {
      display: block;
      cursor: pointer;
      padding: 10px 0 10px 58px;
      &:hover {
        background-color: #1e435d;
        border-radius: 6px;
        transition: all ease 0.2s;
      }
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
  li a.active {
    background-color: #1e435d;
    border-radius: 6px;
    transition: all ease 0.2s;
  }
  .subnav-children {
    padding: 10px 0 10px 58px;
    max-width: 250px;
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg);
        transition: ease all 0.2s;
      }
    }

    .children-list {
      padding: 0;
      margin: 0;
      max-height: 0;
      overflow: hidden;
      transition: ease all 0.2s;
      li {
        margin-top: 5px;
        a {
          padding: 10px 0 10px 30px;
        }
        &:first-child {
          margin-top: 20px;
        }
      }
    }
  }
  .subnav-children.show {
    p {
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(135deg);
        margin-top: 4px;
        transition: ease all 0.2s;
      }
    }
    .children-list {
      max-height: 200px;
      transition: ease all 0.2s;
    }
  }
}
.subnav.show {
  max-height: 500px;
  transition: max-height 0.2s ease-out;
}
