.administration-wrapper {
  display: flex;
  padding: 0;
  max-height: calc(100vh - 90px);
  flex-direction: column;
  overflow: auto;

  .administration-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 30px;
    border-bottom: 1px solid #f4f4f4;
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .header-actions {
      display: flex;
      gap: 30px;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        background-color: #0f2a3c;

        border: none;
        font-size: 14px;
        margin: 0;
        padding: 18px 30px;
        border-radius: 6px;
        span {
          color: #fff;
        }
      }
    }
    @media (max-width: 595px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;

      .header-actions {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .table-search {
          width: 100%;
        }
        .custom-button {
          justify-content: center;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 20px 20px 20px;
        }

        gap: 15px;
      }
    }
  }
  .module-content {
    flex-grow: 1;
    @media (max-width: 540px) {
      ._pagination {
        margin-bottom: 100px;
        @media (max-height: 668px) {
          margin-bottom: 200px; // Doubled if height is below 667px
        }
      }
    }
  }
}

.administration-modal {
  .basic-multi-select {
    margin-bottom: 24px;
  }
}

.pagination {
  margin-top: 42px;
  .page-item {
    span {
      color: #0f2a3c;
    }
    .page-link {
      color: #0f2a3c;
      border: none;
      border-radius: 6px;
      span {
        font-size: 30px;
        color: #666;
        padding-bottom: 7px;
      }
    }
    &:last-child {
      .page-link {
        display: flex;
        align-items: center;
        background-color: initial;
        max-height: 36px;
      }
    }
    &:first-child {
      .page-link {
        display: flex;
        align-items: center;
        background-color: initial;
        max-height: 36px;
      }
    }
  }
  .page-item.active {
    .page-link {
      background-color: #0f2a3c;
      border: none;
      color: #fff;
    }
  }
}
.custom-input {
  position: relative;
  margin-bottom: 24px;
  span {
    display: block;
    color: #6a6a6a;
    font-size: 14px;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 14px 20px;
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  .user-image {
    width: 42px;
    height: 42px;
    align-self: center;
  }
}
