.modal-outside {
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);

  .modal-dialog {
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    pointer-events: all;
    margin: auto;
    height: 100vh;
    outline: 0;
    max-width: 500px;

    .modal-content {
      width: 100%;
      background-color: #fff;
      .modal-header {
        z-index: 12;
        padding: 10px;
        background-color: #0f2a3c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        span {
          color: #fff;
          cursor: default;
        }
        svg {
          cursor: pointer;
        }
        .close-icon-wrapper {
          display: flex;
          align-items: center;
        }
      }
      .modal-body {
        z-index: 12;
        padding: 10px;
        display: block;
        padding: 24px;
        overflow: auto;
        max-height: 70vh;
      }
      .modal-footer {
        z-index: 12;
        display: flex;
        justify-content: flex-end;
        padding: 12px;
      }
    }
  }
}
