.dashboard-wrapper {
  h2 {
    font-size: 14px;
    color: #0f2a3c;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
    gap: 10px;
    .back-arrow {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 50%;
      background-color: #ccc;
      cursor: pointer;
      transition: all ease 0.2s;
      &::before {
        content: "";
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        width: 10px;
        height: 10px;
        transform-origin: left;
        transform: translate(100%, -30%) rotate(135deg);
      }
      &:hover {
        background-color: #b0b0b0;
      }
    }
  }

  .dashboard-flex-content {
    display: flex;
    max-height: calc(100vh - 158px);
    overflow-y: scroll;
    .dashboard-content-head {
      p {
        font-size: 14px;
        font-weight: 600;
      }
      .dashboard-search-wrapper {
        width: 50%;
      }
    }
    .dashboard-content-head.dashboard-title {
      .input-wrapper {
        width: 50%;
        input {
          margin: 0;
        }
      }
    }
    .dashboard-flex-item {
      min-height: 100vh;
      h3 {
        font-size: 14px;
        font-weight: 600;
      }
      .dashboard-content-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .card-filter-wrapper {
        display: flex;
        gap: 20px;
        .trend-filter {
          width: calc(100% / 3);
        }
      }
    }
    .dashboard-flex-item.w-60 {
      border-right: 1px solid #f4f4f4;
    }
    .dashboard-flex-item.w-40 {
      padding: 30px 18px;
    }
    .dashboard-block {
      padding: 10px 18px;
      border-bottom: 1px solid #f4f4f4;
      .table-list-wrapper {
        .table-list {
          .table-list-row {
            cursor: default;
          }
        }
      }
      h3 {
        margin-bottom: 10px;
      }
      .dashboard-status-wrapper {
        .status {
          max-width: 100%;
        }
      }
      .dashboard-templates-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
      }
      .dashboard-templates-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .dashboard-template {
          flex-basis: calc(50% - 40px);
          .dashboard-template-content {
            border: 1px solid #f4f4f4;
            padding: 14px 16px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            gap: 10px;
            strong {
              display: block;
            }
            .dashboard-template-actions {
              display: flex;
              gap: 8px;
              .template-action {
                display: flex;
                background-color: #f5f5f5;
                border-radius: 6px;
                padding: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .dashboard-table-labels {
        display: flex;
        padding: 0;
        li {
          color: #666;
          font-size: 12px;
          width: calc(100% / 4);
        }
      }
      .dashboard-table-content {
        display: flex;
        align-items: center;
        padding: 0;
        li {
          color: #0f2a3c;
          font-size: 12px;
          width: calc(100% / 4);
        }
      }
    }
  }
}
