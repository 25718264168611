.calendar-wrapper {
  overflow: scroll;

  .rbc-month-view {
    border: none;
    .rbc-header {
      border-bottom: none;
      span {
        font-size: 12px;
        text-transform: uppercase;
        color: #666;
      }
    }
  }
  .rbc-time-view {
    border: none;
  }
}

.rbc-header + .rbc-header {
  border-left: 1px solid #f1f1f1 !important;
}

.calendar-wrapper .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #f1f1f1;
}

.calendar-wrapper .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #f1f1f1;
}

.rbc-month-view .rbc-row {
  margin-top: 8px;
}

.rbc-month-view .rbc-date-cell {
  text-align: center;
  font-size: 12px;
}

.rbc-month-row {
  max-height: initial;
}

.rbc-month-row:nth-child(2) .rbc-row {
  margin: 0;
}

.rbc-month-row:nth-child(2) .rbc-date-cell a {
  font-size: 24px;
}

/* Week view */

.rbc-time-view .rbc-time-header-content,
.rbc-time-view .rbc-time-content > * + * > * {
  border-left: none;
}

.rbc-time-view .rbc-header {
  border-bottom: none;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-view .rbc-day-slot .rbc-time-slot,
.rbc-time-view .rbc-time-header.rbc-overflowing,
.rbc-time-view .rbc-time-content {
  border: none;
}

.rbc-time-view .rbc-header span {
  display: block;
  font-size: 24px;
  color: #666;
  font-weight: 400;
  margin-bottom: 10px;
}

.rbc-time-view .rbc-label {
  font-size: 12px;
  color: #666;
}

.rbc-time-view .rbc-time-header-cell {
  border-bottom: 1px solid #f1f1f1;
}

.rbc-time-view .rbc-time-column {
  border-right: 1px solid #f1f1f1;
}

.rbc-time-view .rbc-time-column:last-child {
  border-right: none;
}

.rbc-time-view .rbc-timeslot-group {
  border-bottom: 1px solid #f1f1f1;
}

.rbc-time-view .rbc-header + .rbc-header {
  border-left: 1px solid #f1f1f1;
}

.rbc-time-view .rbc-time-gutter {
  border-right: none;
}

.rbc-time-view .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: none;
}

.rbc-time-view .rbc-time-column .rbc-timeslot-group {
  min-height: 80px;
}

.rbc-time-view .rbc-time-gutter .rbc-time-slot {
  display: flex;
}

.rbc-time-view .rbc-today {
  background-color: transparent;
}

/* Year view */

.rbc-calendar .year {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.rbc-calendar .year .in-month,
.rbc-calendar .year .prev-month,
.rbc-calendar .year .next-month,
.rbc-calendar .year .day {
  min-width: 50px;
  min-height: 50px;
  border: none;
  background-color: transparent;
}

.rbc-calendar .year .day {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #a1a1a1;
  font-size: 14px;
}

.rbc-calendar .year .month-name {
  text-align: left;
  margin-left: 10px;
  font-weight: 600;
}

.calendar-wrapper .rbc-today,
.calendar-wrapper .rbc-off-range-bg {
  background-color: transparent;
}

.calendar-wrapper .rbc-event,
.calendar-wrapper .rbc-day-slot .rbc-event {
  position: relative;
  background-color: rgba(109, 40, 217, 0.1);
  color: #0369a1;
  font-weight: 600;
  padding: 6px 9px;
  border: none;
}

.calendar-wrapper .rbc-event::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: #8b5cf6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.calendar-wrapper .rbc-event-label {
  display: none;
}

.calendar-wrapper .rbc-event-content p,
.calendar-wrapper .rbc-event-content span {
  font-size: 12px;
  margin: 0;
  color: #6d28d9;
}

.calendar-wrapper .rbc-event.rbc-selected {
  background-color: rgba(109, 40, 217, 0.1);
}

.calendar-wrapper .rbc-event.rbc-selected:focus,
.calendar-wrapper .rbc-event.rbc-selected:focus-visible {
  outline: none;
}

.rbc-calendar .in-month.today {
  background-color: #0f2a3c;
  color: #fff;
  border-radius: 50%;
}
