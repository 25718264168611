.employee-content {
  max-width: calc(100vw - 300px);
  min-height: calc(100vh - 200px);
  overflow: scroll;
  @media (max-width: 786px) {
    max-width: fit-content;
  }
  table {
    min-width: 911px;
    .multi-actions {
      min-width: 105px;
    }
    .inline-list {
      padding: 0;
      display: flex;
      li {
        &::after {
          content: ",";
          margin-right: 3px;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .employee-name {
    width: 25%;
    div {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}

.form-input-wrapper {
  .dropdown-selected {
    max-height: 48px;
  }
}

.popup-list {
  display: none;
  position: absolute;
  left: 0;
  padding: 10px;
  margin: 0;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 2;
  li {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.popup-list.top {
  bottom: 40px;
}

.limited-length {
  position: relative;
  p {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
    span {
      &:not(:last-child)::after {
        content: ", ";
      }
    }
  }

  &:hover {
    .popup-list {
      display: block;
      position: absolute;
      padding: 10px;
      margin: 0;
    }
  }
}
