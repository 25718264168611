.daily-pulse-component {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  border-right: 1px solid #f4f4f4;
  margin-right: 20px;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    margin-right: 5px;
  }
  .daily-pulse-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    @media screen and (max-width: 767px) {
      flex-direction: row-reverse;
      gap: 10px;
      margin-right: 5px;
    }
  }
  .daily-pulse-header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .daily-pulse-title {
    font-weight: 700;
  }
  .moods {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 50px;
    .mood-picker {
      display: flex;
      align-items: start;
      gap: 10px;
      margin-top: 2px;
      .thumbs {
        display: flex;
        cursor: pointer;
      }
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
  .list-days {
    padding: 0 !important;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 4px;
    .date-day {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .grade {
        margin-left: 2px;
        margin-bottom: 2px;
      }
    }
  }
  .mobile-collapsable {
    position: fixed;
    height: 100px;
    background-color: #263e4e;
    top: 90px;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 22;

    .wrapper {
      display: flex;
      justify-content: space-between;

      .moods-picker {
        display: flex;
        gap: 5px;
        align-items: baseline;
      }
    }
  }
}
