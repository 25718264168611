.chatInput {
  padding: 30px;
}
@media screen and (max-width: 630px) {
  .chatInput {
    padding: 5px;
  }
}

.chatInput > form {
  position: relative;
  display: flex;
  justify-content: center;
}

.chatInput > form > input {
  display: none;
}

.chatInput > form > button {
  position: absolute;
  bottom: 20px;
  border: none;
  background-color: transparent;
  right: 16px;
}

@media only screen and (max-width: 450px) {
  .chatInput > form > input {
    width: 40%;
  }
}

.editor-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 20px;
}
.message-toolbar {
  border-bottom: 1px solid #c4cdd5;
  margin-bottom: 0px;
  padding: 6px 5px;
  margin-bottom: 50em;
}
.message-editor {
  font-size: 16px;
  padding: 0px 10px;
}
.chatInput .rdw-option-wrapper {
  height: 30px;
  border: none;
  padding: 0;
}
.chatInput .rdw-option-wrapper:hover,
.chatInput .rdw-option-wrapper:active {
  box-shadow: none;
}
.html-output {
  border: 1px solid silver;
  padding: 20px;
  background-color: #fafafa;
}
.rdw-editor-wrapper {
  background: white;
}
.chatInput span {
  color: black !important;
}
.chatInput .rdw-editor-toolbar {
  order: 2;
  border: none;
  border-top: 1px solid #f4f4f4;
  padding: 17px 0 0;
  margin: 0;
}

.chatInput .rdw-inline-wrapper,
.chatInput .rdw-emoji-wrapper,
.chatInput .rdw-image-wrapper,
.chatInput .rdw-fontsize-wrapper {
  margin: 0;
}

.chatInput .rdw-emoji-modal {
  margin-bottom: 0;
  bottom: 20px;
  top: auto;
}

.chatInput .rdw-image-modal {
  bottom: 20px;
  top: auto;
}
