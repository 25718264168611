.task-settings {
  .task-settings-wrapper {
    label {
      display: block;
      font-size: 14px;
      margin: 10px 0 5px;
    }
  }
}
.task-settings-wrapper,
.task-status-wrapper {
  flex-basis: 30%;
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  padding: 24px;
  h3 {
    margin-bottom: 20px;
  }
  .task-settings-desc {
    margin: 10px 0 20px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .date-picker-holder .react-datepicker__input-container input {
    min-width: 100%;
  }
  .date-picker-holder svg {
    top: 44px;
  }
  .task-settings-footer {
    display: flex;
    justify-content: right;
  }
}

.task-status-wrapper {
  margin-top: 24px;
  ul {
    padding: 0;
    display: flex;
    width: 100%;
    li {
      flex-basis: calc(100% / 3);
      text-align: center;
      border: 1px solid rgba(15, 42, 60, 0.1);
      background-color: #f5f5f5;
      padding: 18px 0;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }
      &:last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
    li.active {
      background-color: #0f2a3c;
      color: #fff;
    }
  }
}

.button-icon {
  &::before {
    content: "";
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    width: 7px;
    height: 12px;
    transform: rotate(45deg);
  }
}
