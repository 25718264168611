.chat-user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-user-header .header-user {
  padding: 0;
  border: none;
  cursor: pointer;
}

.chat-user-header .header-user img,
.chat-user-header .header-user span {
  width: 40px;
  height: 40px;
  font-size: 24px;
}

.chat-user-header .user-contact {
  display: flex;
  align-items: center;
  gap: 5px;
}

.chat-user-header .user-contact button {
  background-color: transparent;
  border: none;
}

.chat-user-header .header-user-text h4 {
  font-weight: 600;
  margin-bottom: 0;
}
