.login {
  width: 100%;
  height: 100vh;
  display: flex;
}

.login-background {
  background: linear-gradient(90deg, #45c7f3 0.07%, #00ada4 99.2%);
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.login-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    margin: 80px 0 40px;
    font-size: 21px;
    font-weight: 600;
  }
  .Auth-form-container {
    min-width: 440px;
    img {
      width: 72px;
    }
    input {
      padding: 16px 12px;
      font-size: 14px;
      background-color: #fff;
    }
    .form-control:focus {
      padding: 16px 12px;
      font-size: 14px;
      border: 1px solid #6d28d9 !important;
    }
  }
  .full-width-button {
    position: relative;
    width: 100%;
    margin-top: 35px;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px rgba(25, 0, 65, 0.08);
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #000;
      font-weight: 600;
    }
    svg {
      position: absolute;
      left: 15px;
    }
  }
}

.login-container > img {
  object-fit: contain;
  width: 100px;
}

.login-container > button {
  margin-top: 20px;
  text-transform: inherit;
  background-color: #ffffff;
  color: #132558;
  border: 1px solid #132558;
}

.login-container > button:hover {
  background-color: #05182f;
  color: white;
}

.login-button svg {
  margin-left: 5px;
}

.login-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  .login-button {
    background-color: #0f2a3c;
    border: none;
    margin: 0;
    &:hover {
      background-color: #0f2a3c;
    }
  }
  p {
    margin: 0;
    span {
      color: #6d28d9;
    }
  }
}

.modal-body::after {
  display: none;
}

// Sacuvano za svaki slucaj
// .modal-footer button {
//   background-color: #0f2a3c;
//   color: #fff;
//   border: none;
//   border-radius: 6px;
//   padding: 15px;
// }

@media (max-width: 600px) {
  .login-container {
    padding: 50px;
    margin-bottom: 50px;
    .Auth-form-container {
      min-width: 100%;
      img {
        width: 52px;
      }
    }
  }
}

@media (max-width: 375px) {
  .login-container {
    padding: 20px;
    margin-bottom: 0;
    align-items: flex-start;
    .Auth-form-container {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
    .login-buttons {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    h1 {
      margin-top: 60px;
    }
    > button {
      padding: 16px;
    }
    .full-width-button {
      padding: 16px;
    }
    .full-width-button {
      font-size: 14px;
    }
  }
  .login-btn-wrapper {
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    .btn {
      width: 100%;
      padding: 16px;
      margin-bottom: 20px;
    }
  }
}
