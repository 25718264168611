.sidebarOption {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 18px 15px;
  margin-bottom: 12px;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  transition: all ease 0.2s;
}

.sidebarOption h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.sidebarOption svg {
  min-width: 20px;
}
.sidebarOption:hover {
  background-color: #fff;
  color: #0f2a3c;
}

.sidebarOption:hover path {
  fill: #0f2a3c;
}
.sidebarOption.active {
  background-color: #fff;
  color: #0f2a3c;
}

.sidebarOption.active path {
  fill: #0f2a3c;
}

.sidebarOption-channel {
  padding: 10px 0;
}

.sidebarOption-hash {
  padding: 10px;
}

.sidebarOption > h3 {
  font-weight: 500;
}
.delete-link {
  position: absolute;
  right: 5px;
}
.sidebarOption-channel {
  width: 100%;
}
.channel-name {
  color: white;
}

.active-chat {
  background-color: #1717f0 !important;
}
