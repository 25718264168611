.timeline-list {
  font-size: 12px;
  color: #666;
  overflow: scroll;
  padding: 5px;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 2rem;

    .dotted-line {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;

      .vertical-line {
        display: block;
        width: 1px;
        height: 100%;
        background-color: #d9d9d9;
      }
      .dot {
        position: absolute;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background-color: #d9d9d9;
      }
    }
    .item {
      margin-bottom: 5px;
    }
  }
}
