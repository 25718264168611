.actions-list {
  list-style: none;
  padding: 8px 0;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  border-radius: 6px;
  li {
    padding: 8px 16px;
    color: #6a6565;
    font-size: 14px;
    font-weight: 500;
  }
  li.danger {
    color: #e00000;
  }
  li:hover {
    background-color: rgba(161, 161, 161, 0.1);
  }
}
