.select-from-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .select-from-list-input {
    background-color: #f5f5f5;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 12px 13px;
    display: flex;
    align-items: center;
    .input-wrapper {
      width: auto;
      flex-grow: 1;
    }
    input {
      border: none;
      padding: 0;
      &:focus {
        background-color: transparent;
      }
    }
    .select-input {
      padding: 0;
    }
    ul.select-input {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin: 0;
      li {
        background-color: #fff;
        font-size: 14px;
        padding: 4px 16px;
        border-radius: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }
  }
  .select-list {
    ul {
      padding: 14px 13px;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      background-color: #f5f5f5;
      li {
        font-size: 14px;
        color: #6a6565;
      }
    }
    .select-list-group {
      padding: 5px 0;
      p {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        &::after {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-bottom: 1px solid #6a6565;
          border-right: 1px solid #6a6565;
          transform: rotate(45deg);
        }
      }
      .checkbox-list {
        height: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
        transition: ease all 0.2s;
        border: none;
        li {
          display: flex;
          align-items: center;
          input {
            margin: 0;
          }
        }
      }
    }
    .select-list-group.show {
      p {
        &::after {
          transform: rotate(-135deg);
          margin-top: 5px;
        }
      }
      .checkbox-list {
        height: auto;
        margin: 10px 0;
      }
    }
  }
}
