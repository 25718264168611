.high-five-wrapper {
  .header-title {
    span {
      cursor: pointer;
    }
  }
}
.high-five-filters {
  width: 100%;
  display: flex;
  gap: 24px;
  padding: 30px 24px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  .filter-item {
    flex-basis: 25%;
    label {
      display: block;
      font-size: 14px;
      color: #6a6a6a;
      margin-bottom: 5px;
    }
  }
}

.high-five-content {
  position: relative;
  display: flex;
  max-height: calc(100vh - 330px);
  overflow-y: scroll;
  .high-five-messages {
    flex-basis: 65%;

    .high-five-messages-head {
      padding: 30px 24px;
      h3 {
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        margin: 0;
        color: #6a6a6a;
      }
      .form-control {
        background-color: #f5f5f5;
        font-size: 14px;
        padding: 16px 35px;
        margin: 20px 0 30px;
      }
      .select-receiver {
        margin: 20px 0 10px;
        width: 30%;
        min-width: 200px;
        margin-bottom: 24px;
      }
    }

    .high-fives-text {
      .date-line {
        position: relative;
        text-align: center;
        span {
          position: relative;
          text-align: center;
          display: inline-block;
          color: #d0d0d0;
          padding: 0 10px;
          z-index: 2;
          background-color: #fff;
          font-size: 12px;
          font-weight: 600;
        }
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          top: 50%;
          left: 0;
          right: 0;
          background-color: #f4f4f4;
          z-index: 0;
        }
      }
    }
    ul {
      padding: 0;
      li {
        display: flex;
        gap: 16px;
        padding: 30px;
        .overlap-images {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            &:last-child {
              margin-left: -15px;
              border: 2px solid #fff;
              width: 44px;
              height: 44px;
              margin-top: -2px;
            }
          }
        }
        .single-message {
          b {
            color: #000;
          }
          p {
            margin: 0;
          }
          span {
            display: block;
            color: #6a6565;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .high-five-profile {
    flex-basis: 35%;
    padding: 30px;
    .profile-info-wrapper {
      border: 1px solid #f4f4f4;
      border-radius: 24px;
      padding: 20px;
      .profile-info {
        text-align: center;
        margin-bottom: 20px;
        img {
          width: 100px;
          border-radius: 50%;
        }
        p {
          color: #0f2a3c;
          margin: 16px 0 6px;
        }
        span {
          font-size: 14px;
          color: #6a6565;
        }
      }
      .given-received {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #f4f4f4;
        padding: 20px;
        li {
          text-align: center;
          color: #6a6565;
          font-size: 14px;
          text-transform: uppercase;
          span {
            display: block;
            color: #0f2a3c;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
    .high-five-lists {
      color: #6a6a6a;
      h3 {
        font-size: 18px;
        font-weight: 600;
        color: #0f2a3c;
        margin-top: 30px;
      }
      ul {
        padding: 0;

        li {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e1e1e1;
          padding: 6px 7px;
          margin-bottom: 8px;
          font-size: 14px;
          .high-five-list-user {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: inline-block;
            }
            p {
              margin: 0;
            }
          }
          .count {
            color: #6a6a6a;
          }
        }
      }
    }
  }
}
