.dropdown {
  min-width: 200px;
  max-height: 56px;
  position: relative;
  .dropdown-selected {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    span {
      color: #666;
      min-width: max-content;
      line-height: 1;
      &::after {
        content: ",";
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: 20px;
      margin-bottom: 2px;
      width: 9px;
      height: 9px;
      border-bottom: 2px solid #a1a1a1;
      border-right: 2px solid #a1a1a1;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    .selected-list {
      display: block;
      span {
        color: #666;
        min-width: max-content;
        margin-right: 10px;
        &::after {
          content: ",";
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.dropdown.dark {
  .dropdown-selected {
    background-color: #f5f5f5;
  }
}

.dropdown.secondary {
  min-width: auto;
}

.dropdown-selected,
.dropdown-list {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  font-size: 14px;
  padding: 18px 20px;
  color: #666;
}

.dropdown-list {
  position: absolute;
  left: 0;
  right: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  z-index: 5;
  padding: 0;
  max-height: 173px;
  overflow: scroll;
  li {
    list-style: none;
    padding: 18px 20px !important;
    cursor: pointer;
    transition: ease all 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  li.active {
    position: relative;
    &::after {
      content: "";
      width: 6px;
      border-bottom: 2px solid #a1a1a1;
      height: 11px;
      border-right: 2px solid #a1a1a1;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .no-option {
    &:hover {
      background-color: initial;
      cursor: initial;
    }
  }
}

.dropdown.secondary .dropdown-selected {
  border: none;
  padding: 4px 16px;
  color: #666;
  border-radius: 40px;
  background-color: #f5f5f5;
}

.dropdown.secondary .dropdown-list {
  border: none;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  li {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-weight: 600;
  }
  li.active {
    &::after {
      content: "";
      width: 6px;
      border-bottom: 2px solid #a1a1a1;
      height: 11px;
      border-right: 2px solid #a1a1a1;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
.disabled {
  .dropdown-selected {
    background-color: #f5f5f5;
    border-color: #e1e1e1;
    span {
      color: #b2afaf;
    }
  }
}

.top-position {
  .dropdown-list {
    bottom: 36px;
  }
}

.table-control {
  width: 200px;
  position: relative;
  // left: 230px;
}
