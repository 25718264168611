.main-chat-wrapper {
  display: grid;
  grid-template-areas: "search user-header" "users-sidebar chat-content";
  grid-template-columns: 320px 1fr;
  grid-template-rows: 95px 1fr;
  min-height: calc(100vh - 90px);
}
@media screen and (max-width: 768px) {
  .main-chat-wrapper {
    grid-template-columns: 200px 1fr;
  }
}
@media screen and (max-width: 630px) {
  .main-chat-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 500px;
  }
}

.grid-item {
  padding: 20px 24px;
}

.main-chat-wrapper .table-search {
  grid-area: search;
  border-bottom: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
}

.main-chat-wrapper .table-search svg {
  position: static;
}

.main-chat-wrapper .table-search .search-wrapper {
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 16px 20px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  cursor: pointer;
}

.main-chat-wrapper .table-search .search-wrapper span {
  font-size: 14px;
  color: #6a6565;
}

.chat-user-header {
  grid-area: user-header;
  border-bottom: 1px solid #f4f4f4;
}

.chat-users-wrapper {
  grid-area: users-sidebar;
  border-right: 1px solid #f4f4f4;
  padding: 20px 0;
  max-height: calc(100vh - 185px);
  overflow-y: scroll;
}

@media screen and (max-width: 630px) {
  .chat-users-wrapper {
    min-height: 200px;
    border-bottom: 1px solid #f4f4f4;
  }
}

.chat-main-content {
  grid-area: chat-content;
  display: flex;
  padding: 0;
}

.chat-main {
  flex-grow: 1;
  padding: 20px 30px 30px;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}

.chat-input-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat-item img {
  max-width: 30%;
}

.group-users-list {
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #f1f1f1;
  margin-top: 20px;
}

.group-users-list img {
  max-width: 40px;
  border-radius: 50%;
}

.group-users-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.group-users-list li.add-people {
  cursor: pointer;
}

.group-users-list li h4 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.group-users-list li .user-in-group::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #007a5a;
  border-radius: 8px;
  display: block;
}

.group-users-list li .unavailable::after {
  background-color: #febe00;
}

.group-users-list li span {
  color: #6a6565;
  font-size: 14px;
}

.users-search svg {
  top: 16px;
}

.select-people {
  padding-bottom: 24px;
  border-bottom: 1px solid #f1f1f1;
}

.select-people .select__control {
  padding: 7px 20px;
}
.select-people .select__indicator {
  display: none;
}
.modal-content .overflow-body {
  overflow: visible;
}

.user-info-offcanvas {
  top: 184px !important;
}
