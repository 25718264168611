.projects-top-nav {
  padding: 0;
  margin: 0 0 30px;
  display: flex;
  gap: 40px;
  li {
    cursor: pointer;
    color: #0f2a3c;
    font-weight: 600;
    transition: ease all 0.2s;
    border-bottom: 2px solid transparent;
  }
  li.active {
    color: #6d28d9;
    border-bottom: 2px solid #6d28d9;
  }
}
.projects-wrapper {
  .projects-header.header-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    .btn {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      grid-gap: 10px;
      gap: 10px;
      background-color: #0f2a3c;
      color: #fff;
      border: none;
      font-size: 14px;
      margin: 0;
      padding: 18px 30px;
      border-radius: 6px;
      span {
        color: #fff;
      }
    }
  }
  .add-field {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #6d28d9;
    margin-top: 20px;
    cursor: pointer;
    svg {
      path {
        fill: #6d28d9;
      }
    }
  }
}

.project-form-wrapper {
  padding: 30px;
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 20px;
  }
  p {
    color: #6a6a6a;
    font-size: 14px;
  }
  .form-section {
    .project-tasks-list {
      table {
        width: 100%;
        .task {
          width: 100%;
          border: 1px solid #f5f5f5;
          td {
            padding: 20px 8px;
            svg {
              margin-right: 8px;
              top: 50%;
              transform: translate(-50% 0);
            }
            .actions {
              display: flex;
              justify-content: flex-end;
              gap: 8px;
              svg {
                margin: 0;
                top: 0;
                transform: translate(0);
              }
            }
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
  .project-form-group {
    margin-bottom: 20px;
    .form-control {
      margin: 0;
      border: 1px solid #e1e1e1;
      color: #666;
      font-size: 14px;
    }
    label {
      min-width: 200px;
      color: #666666;
      margin-bottom: 16px;
    }
    input {
      min-height: 56px;
      font-size: 14px;
      max-width: 460px;
      min-width: 200px;
      margin: 0;
      border: 1px solid #e1e1e1;
      color: #666;
      background-color: #f5f5f5;
    }
  }
  .project-flex-input {
    display: flex;
    gap: 24px;
    .project-form-group {
      margin-bottom: 0;
    }
    .input-icon-wrapper {
      input {
        background-color: #f5f5f5;
        padding: 14px 10px 14px 55px;
        min-height: 56px;
      }
      svg {
        position: absolute;
        left: 20px;
        z-index: 2;
        top: 16px;
      }
    }
  }
}

.project-form-group {
  max-width: 460px;
  .basic-multi-select {
    .select__control {
      max-height: 56px;
    }
  }
  .basic-multi-select.users-select {
    .select__control {
      max-height: 111px;
      overflow: scroll;
    }
  }
}

.projects-content {
  .custom-action {
    display: flex;
    align-items: center;
    gap: 20px;
    p {
      margin: 0;
    }
  }
  .table-wrapper {
    max-height: calc(100vh - 550px);
  }
  @media screen and (max-width: 768) {
    .table-wrapper {
      max-height: calc(100vh - 400px);
    }
  }
}

.single-project-content {
  margin: 0 30px;
  .single-project-content-top {
    padding: 20px 0;
    border-bottom: 1px solid #f4f4f4;
    p {
      margin: 0;
      color: #6a6565;
    }
    .single-project-top-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      h3 {
        font-size: 18px;
        font-weight: 600;
      }
      .single-project-top-right {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        span.status.status {
          color: #000;
          font-size: 14px;
          border-radius: 40px;
          text-align: center;
        }
        span.status.green {
          padding: 4px 16px;
          background-color: rgba(0, 223, 165, 0.13);
          color: #00b083;
        }
        span.status.red {
          padding: 4px 16px;
          background-color: #c50813;
          color: white !important;
        }
        span.status.yellow {
          padding: 4px 16px;
          background-color: rgba(0, 223, 165, 0.13);
          color: #00b083;
        }
        span.status.purple {
          padding: 4px 16px;
          background-color: rgba(139, 92, 246, 0.2);
          color: #8b5cf6;
        }
        span.status.blue {
          padding: 4px 16px;
          background-color: #f4f4f4;
          color: #666;
        }
        span.status.grey {
          padding: 4px 16px;
          background-color: #f5f5f5;
          color: #666;
        }
        span.status.rest {
          padding: 4px 16px;
          background-color: #f5f5f5;
          color: #666;
        }
      }
    }
  }
  .single-project-content-users {
    padding: 20px 0;
    border-bottom: 1px solid #f4f4f4;
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .single-project-users {
      margin: 20px 0;
      .single-project-user {
        gap: 10px;
        span {
          font-size: 14px;
        }
      }
    }
  }

  .single-project-content-tasks {
    padding: 20px 0;
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .single-project-tasks-wrapper {
      gap: 8px;
      .single-project-task {
        padding: 20px 20px;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
        .single-project-task-title {
          max-width: 64px;
        }
        .single-project-status {
          max-width: 34px;
        }
        .single-project-task-date {
          gap: 10px;
        }
      }
    }
  }
}
