.user-icon {
  width: auto;
  display: flex;
  justify-content: center;
  img {
    width: 32px;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
  .default-icon {
    width: 32px;
    height: auto;
    aspect-ratio: 1 / 1;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    border-radius: 50%;
    color: white;
  }
}
