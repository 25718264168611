.meetings-dashboard {
  max-height: calc(100vh - 90px);
  overflow: hidden;
  overflow-y: scroll;
  h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  .meetings-dashboard-header {
    padding: 18px 30px;
    border-bottom: 1px solid #f4f4f4;
  }
  .meetings-statistics {
    padding: 20px 18px;
    border-bottom: 1px solid #f4f4f4;
    h2 {
      margin-bottom: 10px;
    }
    .trend-filter {
      flex-basis: calc(100% / 7);
      gap: 5px;
    }
  }
  .meetings-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .all-meetings,
  .talking-points {
    display: flex;
    padding: 20px;
    .all-meetings-block,
    .talking-points-block {
      .all-meetings-block-header {
        padding: 0 12px 12px;
      }
      .type-statistics-content {
        h4 {
          font-size: 12px;
          font-weight: 400;
          margin: 20px 0;
        }
        ul {
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 15px;
          li {
            display: flex;
            align-items: center;
            gap: 5px;
            .type-circle {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }
            p {
              font-size: 12px;
              color: #666;
            }
            .type-statistics-label {
              p {
                color: #0f2a3c;
                font-size: 12px;
              }
              span {
                color: #969696;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    .type-statistics-block {
      padding-left: 10px;
    }
    .all-meetings-middle-block {
      .all-meetings-block-content {
        display: flex;
        flex-direction: column;
        gap: 26px;
        padding: 0 12px;
        border-left: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
      }
    }
    .bordered-left {
      .meetings-block-header {
        padding-left: 10px;
      }
      .all-meetings-block-content {
        padding-left: 10px;
        border-left: 1px solid #d3d3d3;
      }
    }
    .meetings-bar-chart-wrapper {
      padding: 10px 14px;
      margin: 12px 12px 0 0;
      border: 2px solid #d5e7f2;
      border-radius: 5px;
      h3 {
        margin-bottom: 10px;
      }
      .meetings-chart-wrapper {
        .meetings-bar-chart-visible {
          max-height: 200px;
          overflow: hidden;
          overflow-y: scroll;
        }
      }
      .meetings-bar-chart-scale {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        li {
          font-size: 12px;
          flex-grow: 1;
          text-align: right;
          margin-right: -7px;
        }
      }
    }
  }
  .meetings-amout {
    padding: 20px;
    .meeetings-amount-list {
      padding: 20px 0 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
      overflow: hidden;
      max-height: 300px;
      overflow-y: scroll;
      .meetings-amount-list-header {
        display: flex;
        padding: 0 30px 0 20px;
        span {
          font-size: 12px;
          flex: 1;
        }
      }
      li {
        width: 100%;
        .meeting-amount-list-visible {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 14px 24px;
          cursor: pointer;
          border: 1px solid #f4f4f4;
          border-radius: 10px;
          .meetings-amount-list-title {
            font-size: 12px;
            font-weight: 600;
          }
          .meetings-amount-list-expand {
            &::before {
              display: block;
              content: "";
              border-bottom: 2px solid #6a6565;
              border-left: 2px solid #6a6565;
              width: 10px;
              height: 10px;
              transform: rotate(-45deg);
            }
          }
          .meetings-amount-list-expand.open {
            &::before {
              display: block;
              content: "";
              border-bottom: 2px solid #6a6565;
              border-left: 2px solid #6a6565;
              width: 10px;
              height: 10px;
              transform: rotate(135deg);
              margin-top: 2px;
            }
          }
          .meetings-amount-list-row-item {
            flex: 1;
            font-size: 14px;
          }
          .meetings-amount-list-expand {
            flex: 0;
            cursor: pointer;
          }
        }
        .meetings-amount-list-toggle {
          max-height: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 10px;
          background-color: #f9fdff;
          transition: all ease 0.2s;
          overflow: hidden;
          border-color: #d5e7f2;
          padding: 0 24px;
          .meetings-amount-list-toggle-item {
            font-size: 12px;
            p {
              font-weight: 600;
              margin-bottom: 7px;
            }
          }
        }
        .meetings-amount-list-toggle.open {
          max-height: 500px;
          padding: 14px 24px;
          border: 1px solid #d5e7f2;
          margin-top: 15px;
        }
      }
    }
  }
  .type-statistics-chart-empty {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .meeting-amount-list-empty {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 300px);
    height: calc(100vh - 146px);
    background-color: #fff;
  }
}
