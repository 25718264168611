.survey-title {
  font-size: 18px;
  font-weight: 600;
  padding: 20px 0 0 40px;
}
.survey-overview {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
  .survey-overview-question {
    padding: 20px;
    border: 2px solid #b5ddde;
    border-radius: 15px;
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .survey-overview-question-content {
      display: block;
      h3 {
        font-size: 24px;
        font-weight: 400;
        span {
          font-size: 14px;
          display: block;
          margin-top: 5px;
        }
      }
      .doughnut-wrapper {
        display: flex;
        justify-content: center;
        canvas {
          max-height: 350px;
          max-width: 400px;
        }
      }

      .individual-multichoice-wrapper{
        .answer-list {
          li{
            display: flex;
            gap: 8px;
            align-items: center;
          }
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            border: 2px solid #fff;
            outline: 1px solid #5f6368;
            padding: 2px;
          }
          input[type="radio"]:checked {
            background-color: #5f6368;
          }
          input[type="checkbox"] {
            position: relative;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 17px;
            height: 17px;
            border-radius: 3px;
            border: 2px solid #fff;
            outline: 1px solid #5f6368;
            padding: 2px;
          }
          input[type="checkbox"]:checked {
            &::before {
              content: "";
              position: absolute;
              top: 2px;
              left: 1px;
              width: 11px;
              height: 6px;
              border-bottom: 2px solid #6a6565;
              border-left: 2px solid #6a6565;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .no-answers {
        display: flex;
        font-size: 40px;
        height: 10rem;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .answers-list {
        flex-basis: 100%;
      }
    }

    .survey-overview-required {
      display: flex;
      justify-content: end;
      span {
        padding: 5px 15px;
        color: #fff;
        border-radius: 100px;
        background-color: #0f2a3c;
      }
    }
  }
  .survey-overview-question.short-question {
    .survey-overview-question-content {
      display: block;
      .answers-list {
        display: flex;
        flex-direction: column;
        gap: 17px;
        padding: 30px 0;
        max-height: 195px;
        overflow-y: scroll;
        li {
          border: 1px solid #e1e1e1;
          border-radius: 6px;
          padding: 20px;
          background-color: #f5f5f5;
          color: #6a6565;
        }
      }
      .survey-overview-required {
        text-align: right;
      }
    }
  }
  .individual-results{
    .reset{
      display: flex;
      justify-content: end;
    }
  }
}

.radio-list,
    .checkbox-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }