.career-vision-wrapper {
  min-height: calc(100vh - 90px);
  h3 {
    margin-bottom: 20px;
  }
  .career-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 30px;
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .header-actions {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }
}
.header-title {
  span {
    cursor: pointer;
  }
}

.career-vision-content {
  display: flex;
  gap: 24px;
  width: 100%;
  padding: 24px;
  max-height: calc(100vh - 187px);
  overflow: scroll;
  .career-vision-left {
    flex-basis: 65%;
    max-width: 65%;
    .career-growth {
      overflow: scroll;
      margin-bottom: 20px;
      .gantt-legend {
        margin-top: 24px;
        display: flex;
        gap: 32px;
        p {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #a1a1a1;
          font-size: 14px;
          &::before {
            content: "";
            width: 20px;
            height: 20px;
            background-color: #8b5cf6;
            border-radius: 50%;
          }
        }
        p:last-child {
          &::before {
            background-color: #0ea5e9;
          }
        }
      }
    }
  }
  .career-vision-right {
    flex-basis: 35%;
    button {
      margin: 0;
      padding: 18px 40px;
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #0f2a3c;
    }
    .small-matrix-holder {
      border: 1px solid #f4f4f4;
      border-radius: 20px;
      height: 50px;
    }
    .career-board {
      margin-bottom: 30px;
      .no-matrix {
        padding: 40px 20px;
        border: 1px solid #f4f4f4;
        border-radius: 20px;
      }
    }
  }
  .career-block {
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #0f2a3c;
    }
  }

  .scrollable-block {
    max-height: 226px;
    overflow-y: scroll;
    margin-bottom: 30px;
  }
}

.gantt-wrapper {
  border-radius: 20px;
  overflow: hidden;
  max-width: calc(100vw - 674px);
  div {
    overflow: auto;
    &::-webkit-scrollbar {
      display: block;
      -webkit-appearance: none;
      height: 7px;
      width: 7px;
      background-color: #f4f4f4;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(218, 218, 218, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(184, 184, 184, 0.5);
    }
  }
  .calendar {
    rect {
      fill: #f5f5f5;
    }
  }
  ._3eULf {
    div:first-child {
      display: none;
    }
    ._1rLuZ {
      display: none;
    }
    .rows {
      rect {
        fill: #f5f5f5;
      }
    }
    ._9w8d5 {
      font-size: 12px;
    }
    .bar {
      text {
        font-size: 14px;
      }
    }
  }
  ._2k9Ys {
    display: none;
  }
}
.mini-matrix-container {
  position: relative;
  padding: 24px;
  border: 1px solid #f4f4f4;
  border-radius: 24px;
  .popup-wrapper {
    width: 295px;
    position: absolute;
    right: 50%;
    top: 20px;
    padding: 24px;
    border-radius: 20px;
    border-top-right-radius: 0;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .mini-matrix-wrapper {
    position: relative;
    overflow: scroll;
    min-height: 400px;

    .block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      font-weight: 600;
      background-color: #f4f4f4;
      padding: 0;
      border-radius: 12px;

      cursor: pointer;
      img {
        width: 30px;
        border-radius: 50%;
      }
      svg {
        width: 20px;
        path {
          fill: #a1a1a1;
        }
      }
      .user-info {
        position: relative;
        p {
          margin: 0;
        }
        span {
          font-size: 14px;
          color: #6a6565;
        }
      }
      .popup {
        display: none;
        position: absolute;
        top: 50%;
        right: 50%;
        background-color: #fff;
        padding: 14px 12px;
        width: max-content;
        border-radius: 6px;
        border: 1px solid #f6f6f6;
        z-index: 15;
        span {
          color: #6a6565;
        }
      }
      &:hover {
        .popup.popup-show {
          display: block;
        }
      }
    }
    .block.active {
      color: #00b082;
      background-color: #defbf3;
      border: 1px solid #00b082;
      path {
        fill: #00b082;
      }
    }
    .block.disabled {
      color: #a1a1a1;
      background-color: #e2e2e2;
      border: 1px solid #f4f4f4;
      pointer-events: none;
      path {
        fill: #a1a1a1;
      }
    }
    .lines-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  ::-webkit-scrollbar {
    display: block;
    -webkit-appearance: none;
    height: 5px;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #f2f2f2;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.career-block-list {
  margin-top: 40px;
  .career-no-goals {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-radius: 20px;
    padding: 60px;
    .goals-icon {
      padding: 10px;
      border-radius: 50%;
      background-color: #f5f5f5;
      width: 96px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      text-align: center;
      max-width: 375px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    .block-list-item {
      display: flex;
      align-items: center;
      padding: 14px 24px;
      border: 1px solid #f4f4f4;
      border-radius: 10px;
      margin-bottom: 20px;
      gap: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      h5 {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-basis: 45%;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        span {
          font-size: 14px;
          color: #00b083;
        }
      }
      p {
        margin: 0;
      }
      .block-date {
        flex-basis: 35%;
      }
      .status-wrapper {
        flex-basis: 20%;
        .status {
          padding: 4px 16px;
          text-align: center;
          border-radius: 40px;
          max-width: max-content;
        }
        .status.blue {
          background-color: rgba(14, 165, 233, 0.2);
          color: #0ea5e9;
        }
        .status.purple {
          background-color: rgba(139, 92, 246, 0.2);
          color: #8b5cf6;
        }
        .status.green {
          background-color: rgba(0, 223, 165, 0.13);
          color: #00b083;
        }
        .status.red {
          background-color: rgba(226, 0, 15, 0.2);
          color: #b70000;
        }
      }
      &::after {
        content: "";
        width: 13px;
        height: 13px;
        transform: rotate(-45deg);
        border-bottom: 2px solid #6a6565;
        border-right: 2px solid #6a6565;
      }
    }
  }
}

.career-feedback {
  padding-bottom: 40px;
  h4,
  h5 {
    font-size: 16px;
    font-weight: 600;
  }
  p {
    color: #6a6565;
    margin-bottom: 30px;
  }
  button {
    background-color: #0f2a3c;
    margin: 0;
    &:hover {
      background-color: #0f2a3c;
    }
  }
  fieldset:disabled .btn {
    background-color: #f5f5f5;
    color: #6a6565;
  }
  .button-wrapper,
  .input-wrapper {
    display: flex;
    gap: 20px;
  }
  .input-wrapper {
    margin: 40px 0;
    label {
      color: #6a6a6a;
    }
  }
  .no-feedback {
    margin: 40px 0 20px;
  }
}

.career-high-fives {
  p {
    margin: 0;
  }
  .message {
    padding: 0;
    color: #6a6565;
  }
  .high-five-message-wrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 16px;
    .user-images {
      display: flex;
      .user-icon {
        &:not(:first-child) {
          margin-left: -15px;
          border: 2px solid #fff;
          width: 44px;
          height: 44px;
          margin-top: -2px;
          border-radius: 50%;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.mini-matrix-container {
  .react-flow__node {
    z-index: 1 !important;
  }
  .custom-block {
    padding: 0;
    .block {
      padding: 10px 15px;
    }
  }
}
